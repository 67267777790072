<template>
    <div>
        <div>
            <h1 style="margin-top: 10px;">发送通知</h1>

            <div class="lysoft-form-table" style="height: 60px;">
                <el-button type="primary" style="float: right;margin-top: -10px;margin-bottom: 5px;margin-right: 10px"
                    @click="submitNotify">提交</el-button>

                <el-button type="primary" style="float: right;margin-top: -10px;margin-bottom: 5px; margin-right: 10px;"
                    @click="handleClick">选择用户</el-button>
            </div>
            <el-row>
                <el-col :span="12" style="width: 20%;">
                    <div class="grid-content bg-purple">
                        <div style="height: 60px;line-height:40px">
                            <span style="color: white ; float: right;" class="area-text">内容：</span>
                        </div>
                        <div style="height: 60px;line-height:40px">
                            <span style="color: white ; float: right" class="area-text">跳转文本：</span>
                        </div>
                        <div style="height: 60px;line-height:40px">
                            <span style="color: white ; float: right" class="area-text">跳转链接：</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" style="width: 60%;">
                    <div class="grid-content bg-purple-light">
                        <el-input style="height: 60px " placeholder="请输入内容" v-model="notifyData.tz0101">
                        </el-input>
                        <el-input style="height: 60px" placeholder="请输入内容" v-model="notifyData.tz0102">
                        </el-input>
                        <el-input style="height: 60px" placeholder="请输入内容" v-model="notifyData.tz0103">
                        </el-input>
                    </div>
                </el-col>
            </el-row>

        </div>
        <!-- <div style="border: 1px solid #ccc;margin-top: 40px;">
            <Toolbar style="border-bottom: 1px solid #ccc;width: 100%!important;" :editor="editor"
                :defaultConfig="toolbarConfig" :mode="mode" />
            <Editor style="height: 800px; overflow-y: hidden" v-model="html" :defaultConfig="editorConfig" :mode="mode"
                @onCreated="onCreated" />
        </div> -->


        <el-dialog title="提示" :visible.sync="dialogVisible" width="60%" :modal-append-to-body="false">
            <SelectUserNotify ref="SelectUserNotify" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitUser1()">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import axios from "axios";
import SelectUserNotify from "./SelectUserNotify.vue";
// import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
// import { IEditorConfig } from "@wangeditor/editor";

export default {
    components: { SelectUserNotify },
    data() {
        return {
            NewModelData: {},
            fromData: {},
            dialogVisible: false,
            transData: {},
            userList: [],
            notifyData: {
                u00: [],
            },
        };
    },
    methods: {
        submitNotify() {
            this.fromData.content = this.editor.getText()
            this.fromData.userList = this.userList.join(",")
            axios.post("/api/ar/arSendNotify", this.fromData, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((res) => {
                this.$message({
                    message: "发送成功",
                    type: "success"
                });
            });
        },
        submitNotify() {
            // this.dialogVisible = !this.dialogVisible
            const targetComponent = this.$refs.SelectUserNotify;
            if (targetComponent) {
                this.userList = targetComponent.multipleSelection
            }
                for (let user of this.userList) {
                    this.notifyData.u00.push(user.u0000);
                }
                this.notifyData.u00 = this.notifyData.u00.join(",")
                if (this.notifyData.u00&&this.notifyData.tz0101) {
                axios.post("/api/UserCenter/sendNotify/tzPublish", this.notifyData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then((res) => {
                    if (res.data.code === "200") {
                        this.$message({
                            message: "发送成功",
                            type: "success"
                        });
                    }
                });
            }
        },
        submitUser1() {
            this.dialogVisible = !this.dialogVisible
        },
        handleClick() {
            this.dialogVisible = !this.dialogVisible
            const targetComponent = this.$refs.SelectUser;
            if (targetComponent) {
                // targetComponent.$data.fromData1.recordid = row.recordid
                targetComponent.getPerUserList()

            }
            // this.formData.type = row.type
        },
    },

};
</script>

<style>
@import "@/assets/css/Pretty-Header-.css";
@import "@wangeditor/editor/dist/css/style.css";

#editor—wrapper {
    border: 1px solid #ccc;
    z-index: 100;
    /* 按需定义 */
}

#toolbar-container12 {
    border-bottom: 1px solid #ccc;
}

#editor-container12 {
    height: 500px;
}

.w-e-bar-show {
    width: 100% !important;
}
</style>



// axios
// .post("/api/ar/arGetAll",this.fromData, {
// headers: {
// 'Content-Type': 'multipart/form-data'
// }
// })
// .then((res) => {
// this.contData = res.data.data;
// })