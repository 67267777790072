<template>
    <div>
        <h1 style="margin-top: 10px;">角色管理</h1>

        <el-button type="primary" style="float: right;margin-top: -10px;margin-bottom: 5px;"
            @click="newRole">新增</el-button>

        <el-table :data="RoleData" border style="width: 100%">
            <el-table-column fixed prop="permissionDescription" label="名称" width="150">
            </el-table-column>
            <el-table-column prop="permissionCODE" label="权限值" width="120">
            </el-table-column>
            <el-table-column prop="modelName" label="模块名称" width="400">
            </el-table-column>
            <el-table-column prop="porder" label="排序" width="120">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">授权</el-button>
                    <el-button @click="deleteRole(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="提示" :visible.sync="dialogVisible" width="60%" :modal-append-to-body="false">
            <SelectModel ref="SelectModel" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitUser()">确 定</el-button>
            </span>
        </el-dialog>



        <el-dialog title="新增" :visible.sync="dialogVisible1" width="400px" :modal-append-to-body="false">
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">名称：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-input v-model="NewModelData.permissionDescription" placeholder="请输入内容"
                        style="margin-left: auto;margin-right: auto;"></el-input>
                </div>
            </div>
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">排序：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-input v-model="NewModelData.porder" placeholder="请输入内容"
                        style="margin-left: auto;margin-right: auto;"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible1 = false">取 消</el-button>
                <el-button type="primary" @click="submitModel()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="提示" :visible.sync="dialogVisible2" width="30%" :before-close="handleClose"
            :modal-append-to-body="false">
            <span>请注意，此操作不可恢复，确认要删除吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="deleteU0002()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import SelectModel from "./SelectModel.vue";
export default {
    components: {
        SelectModel
    },
    data() {
        return {
            formData: {
                u0002: ''
            },
            RoleData: [],
            dialogVisible: false,
            PerRoleData: {},
            routerINFOList: {},
            dialogVisible1: false,
            dialogVisible2: false,
            NewModelData: {},
        };
    },
    methods: {
        getAllRole() {
            axios
                .post("/api/UserCenter/RoleCenter/user/AdminGetAllRole", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.RoleData = res.data.data;
                })
        },
        handleClick(row) {
            // this.dialogVisible=!this.dialogVisible
            // this.formData.u0002 = row.u0002

            this.dialogVisible = !this.dialogVisible
            this.formData.u0002 = row.u0002

            const targetComponent = this.$refs.SelectModel;
            if (targetComponent) {
                targetComponent.$data.value = row.u0002
                // targetComponent.getPerUserList()
            } 

        },
        deleteRole(row) {
            this.dialogVisible2 = true
            // this.formData.u0002 = row.u0002
            this.formData.u0002 = row.u0002
        },
        deleteU0002() {
            axios
                .post(`/api/UserCenter/RoleCenter/user/AdminDeleteRole`, this.formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.openNotification('top-right', 'success', "提示", "操作成功")
                        this.getAllRole()
                        this.dialogVisible2 = false
                    }
                })
        },
        submitUser() {
            this.dialogVisible = false
            const targetComponent = this.$refs.SelectModel;
            if (targetComponent) {
                console.log(targetComponent.multipleSelection)
                // this.formData.userList = targetComponent.multipleSelection
                this.routerINFOList = targetComponent.multipleSelection
                axios
                    .post(`/api/UserCenter/RoleCenter/user/AdminAddPerModelList?u0002=${this.formData.u0002}`, this.routerINFOList)
                    .then((res) => {
                        // this.contData = res.data.data;
                        targetComponent.multipleSelection = {}
                        if (res.data.code == "200") {
                            this.openNotification('top-right', 'success', "提示", "操作成功")
                        }
                    })
            }
        },
        newRole() {
            this.dialogVisible1 = !this.dialogVisible1
        },
        openNotification(position = null, color, info, info2) {
            const noti = this.$vs.notification({
                progress: 'auto',
                color,
                position,
                title: info,
                text: info2
            })
        },
        submitModel() {
            axios
                .post(`/api/UserCenter/RoleCenter/user/AdminAddNewRole`, this.NewModelData)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.dialogVisible1 = !this.dialogVisible1
                        this.openNotification('top-right', 'success', "提示", "操作成功")
                        this.getAllRole()
                    }
                    // this.contData = res.data.data;
                    this.NewModelData = {}
                })
        }

    },
    mounted() {
        this.getAllRole()
    }
};
</script>

<style scoped>
.lysoft-form-table {
    margin-bottom: 10px;
}
</style>



// axios
// .post("/api/ar/arGetAll",this.fromData, {
// headers: {
// 'Content-Type': 'multipart/form-data'
// }
// })
// .then((res) => {
// this.contData = res.data.data;
// })