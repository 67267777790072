<template>
    <div>
        <el-select v-model="value" filterable placeholder="请选择">
                <el-option v-for="item in tableData2" :key="item.u0002" :label="item.permissionDescription"
                    :value="item.u0002">
                </el-option>
        </el-select>
    </div>
</template>





<script>
import axios from "axios";
export default {
    data() {
        return {
            tableData2: {},
            formData: {},
            multipleSelection: [],
            value: '',

        };
    },
    methods: {

        getRoleList() {
            axios
                .post("/api/UserCenter/RoleCenter/user/AdminGetAllRole", this.formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.tableData2 = res.data.data;
                })
        },
    },
    mounted() {
        this.getRoleList()
    }
};
</script>
  
<style scoped></style>
  


  // axios
  //       .post("/api/ar/arGetAll",this.fromData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     })
  //       .then((res) => {
  //         this.contData = res.data.data;
  //       })