<template>
    <div>
        <h1 style="margin-top: 10px;">词典管理</h1>
        <div class="lysoft-form-table">
            <span style="display: inline-block ;color: white ;" class="area-text">分类：</span>
            <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>

            <el-button type="primary" style="float: right;margin-top: -10px;margin-bottom: 5px;margin-left: 10px;"
                @click="newTable1()">新增分类</el-button>

            <el-button type="primary" style="float: right;margin-top: -10px;margin-left:10px;margin-bottom: 5px;"
                @click="newTable()">新增词条</el-button>

            <el-button type="primary"
                style="float: right;margin-top: -10px;margin-left: 10px;margin-right: 10px;margin-bottom: 5px;"
                @click="DicReBuild()">构建</el-button>

        </div>
        <el-table :data="contData.list" border style="width: 100%">
            <el-table-column fixed prop="word" label="词典" width="800">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button type="text" @click="deleteModel(scope.row)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>


        <div style="bottom: 20px">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage3"
                :page-size="contData.size" :page-count="contData.pages" layout="prev, pager, next, jumper"
                class="pageTool center">
            </el-pagination>
            <!-- <el-button @click="toggleSelection([tableData[1], tableData[2]])">确定</el-button> -->
            <!-- <el-button @click="toggleSelection()">取消选择</el-button> -->
        </div>


        <el-dialog title="提示" :visible.sync="dialogVisible6" width="400px" :modal-append-to-body="false">

            <span style="display: inline-block ;color: #000000 ;" class="area-text">名称：</span>

            <el-input v-model="input1" style="width:300px" placeholder="请输入内容"></el-input>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible6 = false">取 消</el-button>
                <el-button type="primary" @click="UpdateDicByItem1()">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog title="提示" :visible.sync="dialogVisible" width="400px" :modal-append-to-body="false">

            <span style="display: inline-block ;color: #000000 ;" class="area-text">词典： {{ this.name.word }}</span>
            <br>
            <br>
            <span style="display: inline-block ;color: #000000 ;" class="area-text">修改：</span>

            <el-input v-model="input" style="width:300px" placeholder="请输入内容"></el-input>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="UpdateDicByItem()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="提示" :visible.sync="dialogVisible2" width="30%" :modal-append-to-body="false">
            <span>请注意，此操作不可恢复，确认要删除吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="deleteModel1()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="新增" :visible.sync="dialogVisible1" width="400px" :modal-append-to-body="false">
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">名称：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-select v-model="value1" placeholder="请选择" style="width: 100%!important;">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">内容：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-input v-model="NewData.word" placeholder="请输入内容"
                        style="margin-left: auto;margin-right: auto;"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible1 = false">取 消</el-button>
                <el-button type="primary" @click="submitNew()">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>

<script>
import axios from "axios";
import SelectRole from "./SelectRole.vue";
export default {
    components: {
        SelectRole
    },
    data() {
        return {
            tableData2: [],
            contData: {},
            fromData: {
                pageSize: 16,
                pageNum: 1,
                type: "0",
            },
            currentPage3: 1,
            dialogVisible: false,
            dialogVisible1: false,
            dialogVisible2: false,
            dialogVisible6: false,
            name: {},
            name1: {},
            UserData: {},
            options: [],
            value: '0',
            value1: '0',
            input: '',
            input1: '',
            NewData: {},
        };
    },
    watch: {
        value(old, now) {
            console.log('value已经被修改为', old);
            this.fromData.type = old
            this.DicGetDataByType()
            // 这里可以编写其他逻辑或调用其他方法
        }
    },
    methods: {
        submitNew() {
            this.NewData.type = this.value1
            axios
                .post(`/api/UserCenter/ArDicController/InsertDicByItem`, this.NewData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        this.dialogVisible1 = false;
                        this.openNotification('top-right', 'success', "提示", "操作成功")
                        this.DicGetDataByType()
                    } else {
                        this.openNotification('top-right', 'error', "提示", "操作失败")
                    }
                })
        },
        DicReBuild() {
            this.openNotification('top-right', 'success', "提示", "任务已提交至队列，此操作较为耗时，请勿重复提交")
            axios
                .post(`/api/UserCenter/ArDicController/DicReBuild`)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.dialogVisible1 = false;
                        this.openNotification('top-right', 'success', "提示", "重构成功")
                    } else {
                        this.openNotification('top-right', 'error', "提示", "操作失败")
                    }
                })
        },
        newTable() {
            this.dialogVisible1 = true
        },
        newTable1() {
            this.dialogVisible6 = true
        },
        DicGetType() {
            axios
                .post(`/api/UserCenter/ArDicController/DicGetType`, {
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        this.options = res.data.data
                    }
                })
        },
        DicGetDataByType() {
            axios
                .post("/api/UserCenter/ArDicController/DicGetDataByType", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.contData = res.data.data
                    // this.tableData2 = res.data.data.list;
                })
        },
        deleteModel(row) {
            // console.log(row)
            this.dialogVisible2 = true;
            this.UserData.recordId = row.recordId
            this.UserData.type = this.value
        },
        openNotification(position = null, color, info, info2) {
            const noti = this.$vs.notification({
                progress: 'auto',
                color,
                position,
                title: info,
                text: info2
            })
        },
        deleteModel1() {
            axios
                .post(`/api/UserCenter/ArDicController/DeleteDicByType`, this.UserData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        this.dialogVisible2 = false;
                        this.openNotification('top-right', 'success', "提示", "操作成功")
                        this.DicGetDataByType()
                    } else {
                        this.openNotification('top-right', 'error', "提示", "操作失败")
                    }
                })
        },

        handleClick(row) {
            this.dialogVisible = !this.dialogVisible
            this.name = row
            this.input = row.word

        },
        handleCurrentChange() {
            this.fromData.pageNum = this.currentPage3
            this.DicGetDataByType()
        },
        UpdateDicByItem() {
            // this.dialogVisible = false
            if (this.name.word === this.input) {
                this.dialogVisible = false;
                this.openNotification('top-right', 'success', "提示", "操作成功")
                return
            }
            this.name.type = this.value
            this.name.word = this.input
            axios
                .post(`/api/UserCenter/ArDicController/UpdateDicByItem`, this.name, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        this.dialogVisible = false;
                        this.openNotification('top-right', 'success', "提示", "操作成功")
                        this.DicGetDataByType()
                    } else {
                        this.openNotification('top-right', 'error', "提示", "操作失败")
                    }
                })
        },       
        UpdateDicByItem1() {
            this.name1.word = this.input1
            axios
                .post(`/api/UserCenter/ArDicController/InsertDicTypeByItem`, this.name1, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        this.dialogVisible6 = false;
                        this.openNotification('top-right', 'success', "提示", "操作成功")
                        this.DicGetType()
                    } else {
                        this.openNotification('top-right', 'error', "提示", "操作失败")
                    }
                })
        }
    },
    mounted() {
        this.DicGetDataByType()
        this.DicGetType()
    }
};
</script>

<style scoped>
.lysoft-form-table {
    margin-bottom: 10px;
}
</style>



// axios
// .post("/api/ar/arGetAll",this.fromData, {
// headers: {
// 'Content-Type': 'multipart/form-data'
// }
// })
// .then((res) => {
// this.contData = res.data.data;
// })