var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"FABMenu-width"},[_c('nav',{staticClass:"menu-wrapper"},[_vm._m(0),_c('ul',{staticClass:"menu"},[_c('li',{staticClass:"menuitem-wrapper"},[_vm._m(1),_c('svg',{staticClass:"circle-holder"},[_c('circle',{attrs:{"r":"88","cx":"50%","cy":"50%","fill":"none"}})])]),_c('li',{staticClass:"menuitem-wrapper"},[_vm._m(2),_c('svg',{staticClass:"circle-holder"},[_c('circle',{attrs:{"r":"88","cx":"50%","cy":"50%","fill":"none"}})])]),_c('li',{staticClass:"menuitem-wrapper"},[_vm._m(3),_c('svg',{staticClass:"circle-holder"},[_c('circle',{attrs:{"r":"88","cx":"50%","cy":"50%","fill":"none"}})])]),_c('li',{staticClass:"menuitem-wrapper"},[_vm._m(4),_c('svg',{staticClass:"circle-holder"},[_c('circle',{attrs:{"r":"88","cx":"50%","cy":"50%","fill":"none"}})])]),_c('li',{staticClass:"menuitem-wrapper"},[_vm._m(5),_c('svg',{staticClass:"circle-holder"},[_c('circle',{attrs:{"r":"88","cx":"50%","cy":"50%","fill":"none"}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"menu-btn",attrs:{"has-ripple":"true"}},[_c('i'),_c('i'),_c('i'),_c('span',{staticClass:"ripple"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-holder"},[_c('a',{staticClass:"menu-item",attrs:{"href":"#"}},[_c('i',{staticClass:"material-icons"},[_vm._v("home")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-holder"},[_c('a',{staticClass:"menu-item",attrs:{"href":"#"}},[_c('i',{staticClass:"material-icons"},[_vm._v("face")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-holder"},[_c('a',{staticClass:"menu-item",attrs:{"href":"#"}},[_c('i',{staticClass:"material-icons"},[_vm._v("android")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-holder"},[_c('a',{staticClass:"menu-item",attrs:{"href":"#"}},[_c('i',{staticClass:"material-icons"},[_vm._v("shopping_cart")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-holder"},[_c('a',{staticClass:"menu-item",attrs:{"href":"#"}},[_c('i',{staticClass:"material-icons"},[_vm._v("room")])])])
}]

export { render, staticRenderFns }