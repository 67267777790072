<template>
  <div class="content-wrapper app-main">
    <header class="mx-auto" style="padding-bottom: 0px; height: 100%; width: 80%">
      <div class="container12 text-center mx-auto">
        <h3 class="text-primary mx-auto">在此处发布你的文章</h3>
      </div>
      <div class="container12 d-flex align-content-start">
        <span class="text-primary" style="font-size: 20px; width: 7%">标题：</span>
        <input class="d-flex align-content-start" type="text" style="width: 90% ;color: black;" v-model="fromData.title"
          required />
      </div>
      <div class="container12 d-flex" style="margin-top: 10px">
        <span class="text-primary" style="font-size: 20px; margin-left: 11px; width: 16%">上传封面图片：</span>
        <input type="file" style="width: 30%" v-on:change="handleFileUpload" />
      </div>
      <div class="container12 d-flex align-items-xxl-center" style="margin-top: 10px; height: 10%">
        <span class="text-start text-primary d-xxl-flex align-items-xxl-center" style="
            font-size: 20px;
            margin: 0px;
            margin-top: 0px;
            height: 50px;
            width: 7%;
          ">简介：</span>
        <textarea style="width: 35%; height: 100px ;color: black" v-model="fromData.description" required></textarea>
        <img style="width: 15%; height: 100%; margin-left: 91px" :src="transData.url" />
      </div>
      <br>

      <div class="container12">
        <div style="border: 1px solid #ccc">
          <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
          <Editor style="height: 800px; overflow-y: hidden" v-model="html" :defaultConfig="editorConfig" :mode="mode"
            @onCreated="onCreated" />
        </div>
      </div>
      <br />
      <button class="btn btn-primary d-xl-flex mx-auto justify-content-xl-center" type="button"
        style="width: 20%; font-size: 20px" @click="submit">
        提交
      </button>
      <Footer />
    </header>

  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Footer.vue";
import axios from "axios";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { IEditorConfig } from "@wangeditor/editor";

export default {
  components: { Editor, Toolbar, Footer },
  data() {
    return {
      fromData: {
        ar2200: "",
      },
      fromData1: {},
      transData: {},
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          // 配置上传图片
          uploadImage: {
            fieldName: "file",
            server: "/api/ar/arUploadImage/publishEditor",
            maxFileSize: 50 * 1024 * 1024,
            headers: {
              lyToken: `${localStorage.daeeeee_token}`
            }
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },
  methods: {
    submit() {
      console.log(this.editor.getText());
      this.fromData.contentText = this.editor.getText();
      this.fromData.content = this.editor.getHtml();
      if (this.fromData.ar2200) {
        this.update();
      } else {
        this.publish();
      }

    },
    publish() {
      axios
        .post("/api/ar/Publish", this.fromData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          if ((res.data.code == '200')) {
            console.log("上传成功");
            this.openNotification('top-center', 'success', "上传成功", res.data.message)
            this.$router.push('/')
          } else {
            // this.$message.error("上传失败");
            this.openNotification('top-center', 'error', "上传失败", res.data.message)
          }
        });
    },
    update() {
      axios
        .post("/api/ar/Update", this.fromData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          if ((res.data.code == '200')) {
            console.log("上传成功");
            this.openNotification('top-center', 'success', "上传成功", res.data.message)
            this.$router.push('/')
          } else {
            this.openNotification('top-center', 'error', "上传失败", res.data.message)
          }
        });
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    openNotification(position = null, color, info, info2) {
      const noti = this.$vs.notification({
        progress: 'auto',
        color,
        position,
        title: info,
        text: info2
      })
    },
    handleFileUpload(event) {
      const file = event.target.files[0]; // 获取选择的文件
      const formData = new FormData(); // 创建FormData对象
      formData.append('file', file); // 将文件添加到FormData对象中
      // formData.append('',)

      axios.post('/api/ar/arUploadImage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // 设置请求头为multipart/form-data
        }
      })
        .then(response => {
          // 文件上传成功的处理逻辑

          this.transData = response.data.data;
          this.fromData.coverImage = this.transData.url
          console.log(this.fromData);
        })
        .catch(error => {
          // 文件上传失败的处理逻辑
          console.error(error);
        });
    }
  },
  mounted() {
    try{
      this.fromData.ar2200 = this.$route.params.id;
      if (this.fromData.ar2200==='publish') {
        this.fromData.ar2200 = '';
      }
    }catch(e){
      this.fromData.ar2200 = '';
    }
    if (this.fromData.ar2200) {
      axios
        .post(`/api/ar/arGetContent`, this.fromData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.fromData = res.data.data;
          this.transData.url = this.fromData.coverImage;
          this.html = this.fromData.content;
        });
    }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
@import "@/assets/css/Pretty-Header-.css";
@import "@wangeditor/editor/dist/css/style.css";

#editor—wrapper {
  border: 1px solid #ccc;
  z-index: 100;
  /* 按需定义 */
}

#toolbar-container12 {
  border-bottom: 1px solid #ccc;
}

#editor-container12 {
  height: 500px;
}
.w-e-text-container {
  background: rgba(0, 0, 0, 0)!important;
  color: white!important;
}
</style>
