<template>
  <div class="content-wrapper-context" style="width: 95%; max-width: 1200px;">
    <br>
    
    <p style="color: white; font-size: 18px;">在此处发表你的评论</p>
    <hr>
      <div class="row">
        <div>
          <div class="panel comment-box " style="background-color: var(--theme-bg-color);">

            <div class="panel-body" style="height: 120px; ">
              <textarea  v-model="comment.content" style="height: 95%;width: 100%;background-color: var(--theme-bg-color);" placeholder="有什么想说的吗......"  ></textarea>
            </div>
            <div class="panel-footer clearfix" style="border-top: 1px solid #818181;background-color: var(--theme-bg-color);">
              <button class="btn btn-primary pull-right" @click="publishComment">发表 {{comment.content}}</button>
            </div>
          </div>
          <div class="converstation">
            <div class="media" v-for="(item , index) in commentData">
              <div class="media-left">
                <a href="#">
                  <img
                    class="media-object img-circle"
                    src="https://s3.amazonaws.com/uifaces/faces/twitter/fffabs/128.jpg"
                    alt="Avatar"
                  />
                </a>
              </div>
              <div class="media-body">
                <div class="clearfix">
                  <h4 class="media-heading pull-left">
                    {{ item.nickName }}
                    <small><a href="javascript:void(0);">@johndoe</a></small>
                  </h4>
                  <span class="time pull-right">{{item.time}}</span>
                </div>
                <p>
                  {{ item.content }}
                </p>
                <ul class="nav nav-pills">
                  <li role="presentation">
                    <a href="#"><i class="fa fa-comment"></i></a>
                  </li>
                  <li role="presentation">
                    <a href="#"><i class="fa fa-thumbs-o-up"></i></a>
                  </li>
                  <li role="presentation">
                    <a href="#"><i class="fa fa-thumbs-o-down"></i></a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      orderId: 0,
      commentData:[      ],
      comment: ''
    };
  },
  methods: {
    publishComment(){
      console.log(this.comment)
      axios.post(`comment/publish`,this.comment).then(res=>{
        console.log(res.data)
      })
    }
  },
  mounted() {
      this.orderId = this.$route.params.id-10086;
      this.comment.articleId=this.orderId;
      console.log(this.orderId);

      axios.get(`comment/${this.orderId}`).then(res=>{
        console.log(res.data)
        this.commentData=res.data.data;
      })
    },
};
</script>



<style scoped>
</style>


