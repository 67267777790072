<template>
  <div class="content-wrapper app-main">
    <!-- <div style="min-height: 80vh;"> -->
    <div>
      <Home />
      <el-divider content-position="left" >猜你喜欢</el-divider>
      <div class="checkbox-container" style="display: flex;flex-wrap: wrap;justify-content: flex-end;" >
        <el-button style="background-color:Transparent;color: white; "plain @click="newDataChange()">
        <i class='lysoft-flush-button bx bx-refresh'>换一换</i>
      </el-button>
      </div>
    
      <div class="content-section">
        <commendCard ref="commendCard" />
      </div>

      <el-divider content-position="left">分类</el-divider>
      <div>
        <div class="checkbox-container" style="display: flex;flex-wrap: wrap;">
          <!-- <vs-checkbox success v-model="option2">Success</vs-checkbox> -->
          <vs-checkbox success @click="option2(item)" v-for="(item, index) in options1">{{ item.dmdescription
            }}</vs-checkbox>
        </div>
      </div>
      <div class="content-section">
        <cards ref="cards" />
      </div>
    </div>
    <div>
      <el-pagination style="margin-top: 40px;" @size-change="handleSizeChange" @current-change="load"
        :current-page.sync="currentPage3" :page-size="this.contData.size" :page-count="this.contData.pages"
        layout="prev, pager, next, jumper">
      </el-pagination>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios';
// import Cards from "/Cards.vue";
import Cards from "./Cards.vue";
import commendCard from "./commendCard.vue";
import Footer from "./Footer.vue";
import Home from "./Home.vue";
export default {
  components: {
    Home,
    Cards,
    commendCard,
    Footer
  },
  data() {
    return {
      S_CODE: {
        DMClassify: "arType",
      },
      options1: [],
      contData: {},
      codeData: [],
      currentPage3: 1,
    };
  },
  mounted() {
    this.getModelType1();
  },
  afterCreate() {
    this.contData = this.$refs.cards.$data.contData
  },
  methods: {
    newDataChange(){
      this.$refs.commendCard.getCardCount()
    },
    load() {
      this.$refs.cards.$data.fromData.pageNum = this.currentPage3
      this.$refs.cards.getCardCount()
    },
    option2(item) {
      var flag = false;
      this.codeData = []
      if (item.status == undefined) {
        this.$set(item, 'status', false);
      }
      item.status = !item.status;
      for (let i = 0; i < this.options1.length; i++) {
        if (this.options1[i].status == true) {
          this.codeData.push(this.options1[i].dmcod)
          if (this.options1[i].dmcod == "") {
            flag = true;
            break;
          }
        }
      }
      if (flag) {
        this.$refs.cards.$data.fromData.arType = ""
      } else {
        this.$refs.cards.$data.fromData.arType = this.codeData.join(',')
      }
      this.$refs.cards.getCardCount()
    },
    getModelType1() {
      axios
        .post("/api/GetARTYPECode", this.S_CODE, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.options1 = res.data.data;
        })
    },
  },
};
</script>

<style scoped>
.lysoft-flush-button{
  font-size: 15px !important;
  
}

</style>