<template>
  <div class="content-wrapper app-main">

    <div style="height: 120px!important;">
    <h1 class="text-center" style="height: 80px!important;">{{ fromData.title }}</h1>
    <!-- <p class="text-center" style="margin-top: 0px">{{ fromData.nickName }}</p> -->
    <!-- <p class="text-center" style="margin-top: -15px">{{ fromData.createdTime }}</p> -->
    <!-- <hr /> -->
    </div>
      <!-- <div style="background: white!important;"> -->
        <div v-html="fromData.content" style="margin-left: 10px;width: 95%; max-width: 1200px;"></div>
      <!-- </div> -->
    
    <!-- </div> -->
    <!-- </div> -->


    <div class="content-wrapper-context" style="width: 95%; max-width: 1200px;">
      <CommonView />
      
    </div>

    <Footer />
    <AIicon v-if="userId"></AIicon>
    
  </div>
</template>
  
  
<script>
import Footer from "./Footer.vue";
import Comment from "./Comment.vue";
import axios from "axios";
import CommonView from "./CommonView";
import AIicon from "./AIicon.vue";
export default {
  components: {
    Footer,
    Comment,
    CommonView,
    AIicon
  },
  data() {
    return {
      fromData: {},
      ar2200: null,
      commentData: [],
      comment: {},
      userId: false
    };
  },
  methods: {
    publishComment() {
      console.log(this.comment)
      axios.post(`comment/publish`, this.comment).then(res => {
        console.log(res.data)
      })
    }
  },
  mounted() {
    this.fromData.ar2200 = this.$route.params.id;
    this.comment.articleId = this.orderId;
    console.log(this.orderId);

    axios.post(`/api/ar/arGetContent`, this.fromData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      console.log(res.data)
      this.fromData = res.data.data;
    })

    if(localStorage.daeeeee_token.length>40){
      this.userId=true
      axios.post(`/api/ar/ArHisSave`, this.fromData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    }
    // this.userId = this.$parent.isLogin;
    // axios.get(`/api/cm/cmGetContent`, this.fromData, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }).then(res => {
    //   console.log(res.data)
    //   this.commentData = res.data.data;
    // })
  },
};
</script>
  
<style scoped>
.custom-border {
  width: 50%;
  border: 1px solid #cccccc;
}

</style>
  

  