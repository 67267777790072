<template>
<div class="FABMenu-width">
  <nav class="menu-wrapper">
  <button class="menu-btn" has-ripple="true">
    <i></i>
    <i></i>
    <i></i>
    <span class="ripple"></span>
  </button>
  <ul class="menu">
    <li class="menuitem-wrapper">
      <div class="icon-holder">
        <a href="#" class="menu-item">
            <i class="material-icons">home</i>
        </a>
      </div>
      <svg class="circle-holder">
        <circle r="88" cx="50%" cy="50%" fill="none">
        </circle>
      </svg>
    </li>
    <li class="menuitem-wrapper">
      <div class="icon-holder">
        <a href="#" class="menu-item">
            <i class="material-icons">face</i>
        </a>
      </div>
      <svg class="circle-holder">
        <circle r="88" cx="50%" cy="50%" fill="none">
        </circle>
      </svg>
    </li>
    <li class="menuitem-wrapper">
      <div class="icon-holder">
        <a href="#" class="menu-item">
            <i class="material-icons">android</i>
        </a>
      </div>
      <svg class="circle-holder">
        <circle r="88" cx="50%" cy="50%" fill="none">
        </circle>
      </svg>
    </li>
    <li class="menuitem-wrapper">
      <div class="icon-holder">
        <a href="#" class="menu-item">
            <i class="material-icons">shopping_cart</i>
        </a>
      </div>
      <svg class="circle-holder">
        <circle r="88" cx="50%" cy="50%" fill="none">
        </circle>
      </svg>
    </li>
    <li class="menuitem-wrapper">
      <div class="icon-holder">
        <a href="#" class="menu-item">
            <i class="material-icons">room</i>
        </a>
      </div>
      <svg class="circle-holder">
        <circle r="88" cx="50%" cy="50%" fill="none">
        </circle>
      </svg>
    </li>
  </ul>
</nav>
</div>


</template>

<script>
  export default {
    data() {
      return {
      };
    },
    methods: {

      },
  };
</script>
  
<style scoped>

</style>
  