<template>
    <div>
        <el-row>
            <el-col :span="12">
                <div>
                    <el-table ref="multipleTable" :data="tableData2" tooltip-effect="dark" style="width: 100%"
                        @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="modelName" label="模块名称" width="120">
                            <template slot-scope="scope">{{ scope.row.modelName }}</template>
                        </el-table-column>
                        <el-table-column prop="modelUrl" label="路径" width="120">
                        </el-table-column>
                        <el-table-column prop="typeName" label="类型" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <div style="bottom: 20px">
                        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage3"
                            :page-size="contData.size" :page-count="contData.pages" layout="prev, pager, next, jumper"
                            class="pageTool center">
                        </el-pagination>
                        <!-- <el-button @click="toggleSelection([tableData[1], tableData[2]])">确定</el-button> -->
                        <!-- <el-button @click="toggleSelection()">取消选择</el-button> -->
                    </div>
                </div>
            </el-col>
            <el-col :span="12" style="height: 100%!important;">
                <div>
                    <!-- @selection-change="handleSelectionChange1" -->
                    <el-table ref="multipleTable" :data="multipleSelection" tooltip-effect="dark" style="width: 100%">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="modelName" label="模块名称" width="120">
                            <template slot-scope="scope">{{ scope.row.modelName }}</template>
                        </el-table-column>
                        <el-table-column prop="modelUrl" label="路径" width="120">
                        </el-table-column>
                        <el-table-column prop="typeName" label="类型" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>

                </div>
            </el-col>
        </el-row>



    </div>
</template>
  
<script>
import axios from "axios";
export default {
    data() {
        return {
            multipleSelection: [],
            tableData2: [],
            fromData: {
                // query:query,
                pageSize: 100,
                pageNum: 1,
                type: "1",
                u0002:'',
            },            
            fromData1: {
            },
            currentPage3: 1,
            currentPage4: 1,
            contData: [],
            contData1: [],
            data1: {},
            value: '',
        }
    }, watch: {
        value(old, now) {
            console.log('value已经被修改为', old);
            this.fromData1.u0002=old
            this.getPerUserList()
            // 这里可以编写其他逻辑或调用其他方法
    }
},

    methods: {
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                    console.log(this.multipleSelection)
                    // this.tableData2=rows
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        getUserList() {
            axios
                .post("/api/UserCenter/RoleCenter/user/AdminGetModelList", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.contData = res.data.data
                    this.tableData2 = res.data.data.list;
                })
        },
        getPerUserList() {
            // console.log(this.$parent.fromData)
                axios
                    .post("/api/UserCenter/RoleCenter/user/AdminGetModelList", this.fromData1, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((res) => {
                        this.contData1 = res.data.data
                        this.multipleSelection = res.data.data.list;
                    })
        },
        getPerUserList1() {
            // console.log(this.$parent.fromData)
                axios
                    .post("/api/UserCenter/RoleCenter/user/AdminGetModelList", this.fromData1, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((res) => {
                        this.contData1 = res.data.data
                        this.multipleSelection = res.data.data.list;
                    })
        },
        handleCurrentChange() {
            this.fromData.pageNum = this.currentPage3;
            this.getUserList()
        },        
        handleCurrentChange1() {
            this.fromData.pageNum = this.currentPage4;
            this.getPerUserList1()
        },
    },
    mounted() {
        const targetComponent = this.$parent.$parent;
            if (targetComponent) {
                this.data1=targetComponent.$data
                console.log(this.data1.formData.u0002)
                this.fromData1.u0002=this.data1.formData.u0002
            }
        this.getUserList()
        this.getPerUserList()
    }
}
</script>
<style scoped></style>
  