import Vue from 'vue'
import App from './App.vue'
import Navigation from './components/Navigation.vue'
// import UserCenter from './components/UserCenter.vue'
import router from './router'
import axios from "axios";

import './assets/css/style.css'
import './assets/css/styleLogin.css'

// import boxicons from 'boxicons'
// import boxicons 


import JsEncrypt from 'jsencrypt'
import Vuesax from 'vuesax'

// import UserFront from "./assets/js/UserFront.js";

import ElementUI from 'element-ui';
import './assets/css/elementUI.css';

import 'vuesax/dist/vuesax.css' //Vuesax styles

// import Navigation from "./components/Navigation.vue";

import  baseUrl  from '../baseUrl.js' 

Vue.use(Vuesax, {
  // options here
})
Vue.use(ElementUI);

// Vue.use(boxicons);

// Vue.component("Navigation",Navigation);
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import BootstrapVue from 'bootstrap-vue'

// Vue.use(BootstrapVue)

Vue.prototype.$jsEncrypt = JsEncrypt
Vue.config.productionTip = false

 // axios响应拦截(token过期或登陆状态异常)
 axios.interceptors.response.use(function(res){
	// console.log(res)
	if(res.data.code == '302'){
		localStorage.daeeeee_token=""
    // window.location.reload()
	}else if(res.data.code == '301'){
    // localStorage.daeeeee_token="nologin"
    // window.location.reload()
  }
  else {
		// alert('请求出错')
		return res
	}
})

axios.interceptors.request.use(
  config => {
    if (localStorage.daeeeee_token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.lyToken = `${localStorage.daeeeee_token}`;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  });
// axios.defaults.baseURL='http://daeeeee.top'
axios.defaults.baseURL9=baseUrl;
  
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')  
  
  // new Vue({
  //   router,
  //   render: h => h(UserCenter)
  // }).$mount('#UserCenter')