<template>
  <div>
  <div id="apps-card" class="apps-card">
    <div class="app-card" v-for="(item, index) in contData.list">
      <span>
        {{ item.title }}
      </span>
      <img :src="item.coverImage" class="app-card-image">
      <div class="app-card__subtext">
        {{ item.description }}
      </div>
      <div class="app-card-buttons">
        <vs-button icon border :active="1 == 1" @click="routerGo(item.ar2200)">
          <i class='bx bx-show'></i>
        </vs-button>
        <vs-button icon color="danger" border :active="1 == 1" @click="pushCollect(item)" v-if="item.ar2601 == 1">
          <i class='bx bxs-heart'></i>
        </vs-button>
        <vs-button icon color="danger" border :active="0 == 1" @click="pushCollect(item)"
          v-if="item.ar2601 == 0 || item.ar2601 == null">
          <i class='bx bxs-heart'></i>
        </vs-button>
      </div>
    </div>


  </div>

  </div>
</template>
  
<script>
// import 'boxicons';
import axios from "axios";
import Vue from 'vue'
export default {
  data() {
    return {
      fromData: {
        pageSize: 9,
        pageNum: 1,
      },
      currentPage3: 1,
      editor: null,
      html: "<p>hello</p>",
      contData: [],
    };
  },
  mounted() {
    // this.asyncOperations();
    this.getCardCount()
  },
  methods: {
    openNotification(position = null, color, info, info2) {
      const noti = this.$vs.notification({
        progress: 'auto',
        color,
        position,
        title: info,
        text: info2
      })
    },
    getCardCount() {
      return axios
        .post("/api/ar/arGetAll", this.fromData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.contData = res.data.data;
          this.$parent.$data.contData = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    routerGo(num) {
      this.$router.push({
        path: `/contentShow/${num}`,
      });
    },
    pushCollect(item) {
      if (item.ar2601 == 1) {
        item.ar2601 = 0
      } else {
        item.ar2601 = 1
      }
      // alert("213")
      axios
        .post("/api/ar/arPushCollect", item, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.openNotification('top-right', 'success', "提示", "操作成功")
          Vue.set(item, ar2601, res.data.data.ar2601)
          // item.ar2601=res.data.data.ar2601
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<!-- <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'> -->
<style scoped>
/* @import url('https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css'); */
@import "../assets/css/boxicons.min.css";

.card-collect {
  font-size: 30px !important
}
</style>
  