<template>
    <div>
        <div class="main-header-line">
            <h1>数据看板</h1>
            <div class="action-buttons">
                <button class="open-right-area">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-activity">
                        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
                    </svg>
                </button>
                <button class="menu-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-menu">
                        <line x1="3" y1="12" x2="21" y2="12" />
                        <line x1="3" y1="6" x2="21" y2="6" />
                        <line x1="3" y1="18" x2="21" y2="18" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="chart-row three">
            <div class="chart-container-wrapper">
                <div class="chart-container">
                    <div class="chart-info-wrapper">
                        <h2>最受欢迎文章浏览增长</h2>
                        <span>{{ firstData.data1 }}</span>
                    </div>
                    <div class="chart-svg">
                        <svg viewBox="0 0 36 36" class="circular-chart pink">
                            <path class="circle-bg" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <path class="circle" :stroke-dasharray="`${firstData.percentage1}` + ',100'" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <text x="18" y="20.35" class="percentage">{{ firstData.percentage1 }}%</text>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="chart-container-wrapper">
                <div class="chart-container">
                    <div class="chart-info-wrapper">
                        <h2>最受欢迎文章收藏增长</h2>
                        <span>{{ firstData.data2 }}</span>
                    </div>
                    <div class="chart-svg">
                        <svg viewBox="0 0 36 36" class="circular-chart blue">
                            <path class="circle-bg" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <path class="circle" :stroke-dasharray="`${firstData.percentage2}` + ',100'" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <text x="18" y="20.35" class="percentage">{{ firstData.percentage2 }}%</text>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="chart-container-wrapper">
                <div class="chart-container">
                    <div class="chart-info-wrapper">
                        <h2>总浏览增长</h2>
                        <span>{{ firstData.data3 }}</span>
                    </div>
                    <div class="chart-svg">
                        <svg viewBox="0 0 36 36" class="circular-chart orange">
                            <path class="circle-bg" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <path class="circle" :stroke-dasharray="`${firstData.percentage3}` + ',100'" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <text x="18" y="20.35" class="percentage">{{ firstData.percentage3 }}%</text>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="chart-row two">
            <div class="chart-container-wrapper big">
                <div class="chart-container">
                    <div class="chart-container-header">
                        <h2>浏览量</h2>
                        <span>Last 30 days</span>
                    </div>
                    <div class="line-chart">
                        <canvas id="chart"></canvas>
                    </div>
                    <div class="chart-data-details">
                        <div class="chart-details-header"></div>
                    </div>
                </div>

                <div class="chart-container" style="margin-top: 20px;">
                    <div class="chart-container-header">
                        <h2>收藏量</h2>
                        <span>Last 30 days</span>
                    </div>
                    <div class="line-chart">
                        <canvas id="chart1"></canvas>
                    </div>
                    <div class="chart-data-details">
                        <div class="chart-details-header"></div>
                    </div>
                </div>
            </div>

            <div class="chart-container-wrapper small">
                <div class="chart-container">
                    <div class="chart-container-header">
                        <h2>浏览结构</h2>
                        <span href="#">This month</span>
                    </div>
                    <div class="acquisitions-bar">
                        <span :class="getClass(index)" class="bar-progress" v-for="(item, index) in StructureData"
                            :style="{ width: item.percentage + '%' }"></span>
                    </div>
                    <div class="progress-bar-info" v-for="(item, index) in StructureData">
                        <span class="progress-color" :class="getClass(index)"></span>
                        <span class="progress-type">{{ item.title }}</span>
                        <span class="progress-amount">{{ item.percentage }}%</span>
                    </div>
                </div>
                <div class="chart-container">
                    <div class="chart-container-header">
                        <h2>收藏结构</h2>
                        <span href="#">This month</span>
                    </div>
                    <div class="acquisitions-bar">
                        <span :class="getClass(index)" class="bar-progress" v-for="(item, index) in StructureData1"
                            :style="{ width: item.percentage + '%' }"></span>
                    </div>
                    <div class="progress-bar-info" v-for="(item, index) in StructureData1">
                        <span class="progress-color" :class="getClass(index)"></span>
                        <span class="progress-type">{{ item.title }}</span>
                        <span class="progress-amount">{{ item.percentage }}%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            firstData: {},
            StructureData: {},
            StructureData1: {},
            data1: {},
            classList: ['rejected', 'on-hold', 'shortlisted', 'applications'],
            date2: [],
            date3: [],
            date4: [],
        };
    },
    methods: {
        getClass(index) {
            return this.classList[index]
        },

        chartInit() {
            var chart = document.getElementById('chart').getContext('2d'),
                gradient = chart.createLinearGradient(0, 0, 0, 450);
            gradient.addColorStop(0, 'rgba(0, 199, 214, 0.32)');
            gradient.addColorStop(0.3, 'rgba(0, 199, 214, 0.1)');
            gradient.addColorStop(1, 'rgba(0, 199, 214, 0)');
            console.log(this.date2)
            console.log(this.date3)
            var data = {
                // labels: ['January1', 'February1', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                labels: this.date2,
                datasets: [{
                    label: '浏览人次',
                    backgroundColor: gradient,
                    pointBackgroundColor: '#00c7d6',
                    borderWidth: 1,
                    borderColor: '#0e1a2f',
                    data: this.date3
                }]
            };
            var options = {
                responsive: true,
                maintainAspectRatio: true,
                animation: {
                    easing: 'easeInOutQuad',
                    duration: 520
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontColor: '#5e6a81'
                        },
                        gridLines: {
                            color: 'rgba(200, 200, 200, 0.08)',
                            lineWidth: 1
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontColor: '#5e6a81'
                        }
                    }]
                },
                elements: {
                    line: {
                        tension: 0.4
                    }
                },
                legend: {
                    display: false
                },
                point: {
                    backgroundColor: '#00c7d6'
                },
                tooltips: {
                    titleFontFamily: 'Poppins',
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    titleFontColor: 'white',
                    caretSize: 5,
                    cornerRadius: 2,
                    xPadding: 10,
                    yPadding: 10
                }
            };

            var chartInstance = new Chart(chart, {
                type: 'line',
                data: data,
                options: options
            });
        },
        chartInit1() {
            var chart = document.getElementById('chart1').getContext('2d'),
                gradient = chart.createLinearGradient(0, 0, 0, 450);
            gradient.addColorStop(0, 'rgba(0, 199, 214, 0.32)');
            gradient.addColorStop(0.3, 'rgba(0, 199, 214, 0.1)');
            gradient.addColorStop(1, 'rgba(0, 199, 214, 0)');
            var data = {
                labels: this.date2,
                datasets: [{
                    label: '收藏人次',
                    backgroundColor: gradient,
                    pointBackgroundColor: '#00c7d6',
                    borderWidth: 1,
                    borderColor: '#0e1a2f',
                    data: this.date4
                }]
            };
            var options = {
                responsive: true,
                maintainAspectRatio: true,
                animation: {
                    easing: 'easeInOutQuad',
                    duration: 520
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontColor: '#5e6a81'
                        },
                        gridLines: {
                            color: 'rgba(200, 200, 200, 0.08)',
                            lineWidth: 1
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontColor: '#5e6a81'
                        }
                    }]
                },
                elements: {
                    line: {
                        tension: 0.4
                    }
                },
                legend: {
                    display: false
                },
                point: {
                    backgroundColor: '#00c7d6'
                },
                tooltips: {
                    titleFontFamily: 'Poppins',
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    titleFontColor: 'white',
                    caretSize: 5,
                    cornerRadius: 2,
                    xPadding: 10,
                    yPadding: 10
                }
            };

            var chartInstance = new Chart(chart, {
                type: 'line',
                data: data,
                options: options
            });
        },
        getFistData() {
            axios
                .post("/api/UserFront/home/GetHomeData", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.firstData = res.data.data;
                })
        },
        getStructureData() {
            axios
                .post("/api/UserFront/home/GetHomeViewStructureData", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.StructureData = res.data.data;
                })
        },
        getStructureData1() {
            axios
                .post("/api/UserFront/home/GetHomeCollectStructureData", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.StructureData1 = res.data.data;
                })
        },
        GetHomeAnalysisData() {
            axios
                .post("/api/UserFront/home/GetHomeAnalysisData", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.data1 = res.data.data;
                    for (let index = 0; index < this.data1.length; index++) {
                        this.date2.unshift(this.data1[index].date.substring(0, 10))
                        this.date3.unshift(this.data1[index].viewCount)
                        this.date4.unshift(this.data1[index].collectCount)
                    }
                    this.chartInit();
                    this.chartInit1();
                })
        },
    },
    created() {

    },
    mounted() {
        this.getStructureData();
        this.getStructureData1();
        this.getFistData();
        this.GetHomeAnalysisData();
    }
};
</script>
  
<style scoped></style>
  


  // axios
  //       .post("/api/ar/arGetAll",this.fromData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     })
  //       .then((res) => {
  //         this.contData = res.data.data;
  //       })