<template>
  <div>
    <div class="main-header-line">
      <h1>我的收藏</h1>
      <div class="action-buttons">
        <button class="open-right-area">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-activity">
            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
          </svg>
        </button>
        <button class="menu-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-menu">
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
        </button>
      </div>
    </div>

    <div id="apps-card" class="apps-card">
      <div class="app-card" v-for="(item, index) in contData.list">
        <span>
          {{ item.title }}
        </span>
        <img :src="item.coverImage" class="app-card-image">
        <div class="app-card__subtext">
          {{ item.description }}
        </div>
        <div class="app-card-buttons">
          <vs-button icon border :active="1 == 1" @click="routerGo(item.ar2200)">
            <i class='bx bx-show'></i>
          </vs-button>
          <vs-button icon color="danger" border :active="1 == 1" @click="pushCollect(item)" v-if="item.ar2601 == 1">
            <i class='bx bxs-heart'></i>
          </vs-button>
          <vs-button icon color="danger" border :active="0 == 1" @click="pushCollect(item)" v-if="item.ar2601 == 0">
            <i class='bx bxs-heart'></i>
          </vs-button>
        </div>
      </div>
    </div>

    <div class="center">
      <!-- <div class="block"> -->
      <!-- <span class="demonstration">直接前往</span> -->

      <!-- @size-change="handleSizeChange" -->
      <!-- background="false" -->
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage3" :page-size="contData.size"
        :page-count="contData.pages" layout="prev, pager, next, jumper" class="pageTool center">
      </el-pagination>
      <!-- </div> -->
    </div>

    <div class="main-header-line main-header-line-two">
      <h1>最近浏览</h1>
      <div class="action-buttons">
        <button class="open-right-area">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-activity">
            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
          </svg>
        </button>
        <button class="menu-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-menu">
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
        </button>
      </div>
    </div>
    <div>
      <!-- <el-button @click="resetDateFilter">清除日期过滤器</el-button> -->
      <!-- <el-button @click="clearFilter">清除所有过滤器</el-button> -->
      <el-table ref="filterTable" :data="tableData.list" style="width: 100%">
        <el-table-column prop="date" label="日期" sortable width="180" column-key="date"
          
          :filter-method="filterHandler">
        </el-table-column>
        <el-table-column prop="title" label="标题" width="180">
        </el-table-column>
        <el-table-column prop="description" label="描述" :formatter="formatter">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>
        </el-table-column>






        <!-- <el-table-column
      prop="tag"
      label="标签"
      width="100"
      :filters="[{ text: '家', value: '家' }, { text: '公司', value: '公司' }]"
      :filter-method="filterTag"
      filter-placement="bottom-end">
      <template slot-scope="scope">
        <el-tag
          :type="scope.row.tag === '家' ? 'primary' : 'success'"
          disable-transitions>{{scope.row.tag}}</el-tag>
      </template>
    </el-table-column> -->
      </el-table>
      <div class="center">
      <el-pagination @current-change="handleCurrentChange1" :current-page.sync="currentPage4" :page-size="tableData.size"
        :page-count="tableData.pages" layout="prev, pager, next, jumper" class="pageTool center">
      </el-pagination>
    </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from 'vue'
export default {
  data() {
    return {
      fromData: {
        // query:query,
        pageSize: 6,
        pageNum: 1,
        type: "1",
      },      
      fromData1: {
        // query:query,
        pageSize: 12,
        pageNum: 1,
      },
      tableData:{},
      currentPage3: 1,
      currentPage4: 1,
      editor: null,
      html: "<p>hello</p>",
      contData: [],
    };
  },
  mounted() {
    // this.asyncOperations();
    this.getCardCount()
    this.getHis()

  },
  methods: {
    routerGo(num) {
      this.$router.push({
        path: `/contentShow/${num}`,
      });
    },
    handleClick(row) {
      this.$router.push({
        path: `/contentShow/${row.ar2200}`,
      });
    },
    handleCurrentChange() {
      this.fromData.pageNum = this.currentPage3
      axios
        .post("/api/ar/arGetAll", this.fromData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.contData = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },    
    handleCurrentChange1() {
      this.fromData1.pageNum = this.currentPage4
      this.getHis()
    },
    getCardCount() {
      // this.fromData.query=this.query
      return axios
        .post("/api/ar/arGetAll", this.fromData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.contData = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openNotification(position = null, color, info, info2) {
            const noti = this.$vs.notification({
                progress: 'auto',
                color,
                position,
                title: info,
                text: info2
            })
        },
    pushCollect(item) {
      if (item.ar2601 == 1) {
        item.ar2601 = 0
      } else {
        item.ar2601 = 1
      }
      // alert("213")
      axios
        .post("/api/ar/arPushCollect", item, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.openNotification('top-right', 'success', "提示", "操作成功")
          Vue.set(item, ar2601, res.data.data.ar2601)
          // item.ar2601=res.data.data.ar2601
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getHis() {
      axios
        .post("/api/ar/GetArHis", this.fromData1, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.tableData = res.data.data;
        })
    },
  },
};

</script>

<style scoped>
.main-header-line-two {
  margin-top: 30px !important;
}

.pageTool {
  /* margin-left: auto!important;
    margin-right: auto!important; */
  /* margin:0 auto !important; */
  margin-top: 20px;
  text-align: center !important;
  /* color: s=salmon; */
}
</style>
