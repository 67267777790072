<template>
            <div class="content-wrapper-header">
              <div class="content-wrapper-context">
                <h3 class="img-content" style="font-size: 30px; color: aqua;">
                  <svg viewBox="0 0 512 512" style="visibility: hidden;">
                    <path
                      d="M467 0H45C20.099 0 0 20.099 0 45v422c0 24.901 20.099 45 45 45h422c24.901 0 45-20.099 45-45V45c0-24.901-20.099-45-45-45z"
                      fill="#d6355b"
                      data-original="#ff468c"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M512 45v422c0 24.901-20.099 45-45 45H256V0h211c24.901 0 45 20.099 45 45z"
                      fill="#d6355b"
                      data-original="#d72878"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M467 30H45c-8.401 0-15 6.599-15 15v422c0 8.401 6.599 15 15 15h422c8.401 0 15-6.599 15-15V45c0-8.401-6.599-15-15-15z"
                      fill="#2e000a"
                      data-original="#700029"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M482 45v422c0 8.401-6.599 15-15 15H256V30h211c8.401 0 15 6.599 15 15z"
                      fill="#2e000a"
                      data-original="#4d0e06"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M181 391c-41.353 0-75-33.647-75-75 0-8.291 6.709-15 15-15s15 6.709 15 15c0 24.814 20.186 45 45 45s45-20.186 45-45-20.186-45-45-45c-41.353 0-75-33.647-75-75s33.647-75 75-75 75 33.647 75 75c0 8.291-6.709 15-15 15s-15-6.709-15-15c0-24.814-20.186-45-45-45s-45 20.186-45 45 20.186 45 45 45c41.353 0 75 33.647 75 75s-33.647 75-75 75z"
                      fill="#d6355b"
                      data-original="#ff468c"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M391 361h-30c-8.276 0-15-6.724-15-15V211h45c8.291 0 15-6.709 15-15s-6.709-15-15-15h-45v-45c0-8.291-6.709-15-15-15s-15 6.709-15 15v45h-15c-8.291 0-15 6.709-15 15s6.709 15 15 15h15v135c0 24.814 20.186 45 45 45h30c8.291 0 15-6.709 15-15s-6.709-15-15-15z"
                      fill="#d6355b"
                      data-original="#d72878"
                    />
                  </svg>
                  大鹅
                </h3>
                <div class="content-text" style="font-size: 16px;">
                  {{this.fromData.content}}
                </div>
                <div class="content-text" style="font-size: 16px;">
                  千里之行，始于足下。
                </div>
                <button class="content-button" style="visibility: hidden;">Start free trial</button>
              </div>
              <img
                class="content-wrapper-img"
                :src="this.fromData.logo"
                alt=""
              />

          
            </div>

            
</template>

<script>
  import axios from 'axios';
export default {
  data() {
    return {
      fromData:{},
    };
  },
  mounted(){
    console.log("123124124")
    axios.get('/api/aplication/commom',this.fromData).then((res)=>{
      if(res.data.code==200){
        this.fromData=res.data.data
      }
      
    })
  }
};
</script>

<style scoped></style>
