<template>
    <div>
        <el-row>
            <el-col :span="24" style="width: 100%;">
                <div class="grid-content bg-purple-dark" style="width: 100%;">
                    <span style="display: inline-block ;color: #000000 ;" class="area-text">角色：</span>
                    <div style="display: inline-block ;margin-left: auto;margin-right: auto;">
                        <el-select v-model="roleData" multiple placeholder="请选择"
                            style="width: 200px">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                        <span style="display: inline-block  ;color: #000000 ; margin-left: 25px;" class="area-text">昵称：</span>
                        <el-input style="width: 200px; display: inline-block " placeholder="请输入内容"
                            prefix-icon="el-icon-search" v-model="fromData.nickName">
                        </el-input>
                        <el-button type="primary" style="margin-left: 10px;" @click="getUserList()">查询</el-button>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <div>
                    <el-table ref="multipleTable" :data="tableData2" tooltip-effect="dark" style="width: 100%"
                        @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="nickName" label="昵称" width="120">
                            <template slot-scope="scope">{{ scope.row.nickName }}</template>
                        </el-table-column>
                        <el-table-column prop="u0001" label="用户名" width="120">
                        </el-table-column>
                        <el-table-column prop="email" label="邮箱" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <div style="bottom: 20px">
                        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage3"
                            :page-size="contData.size" :page-count="contData.pages" layout="prev, pager, next, jumper"
                            class="pageTool center">
                        </el-pagination>
                        <!-- <el-button @click="toggleSelection([tableData[1], tableData[2]])">确定</el-button> -->
                        <!-- <el-button @click="toggleSelection()">取消选择</el-button> -->
                    </div>
                </div>
            </el-col>
            <el-col :span="12" style="height: 100%!important;">
                <div>
                    <!-- @selection-change="handleSelectionChange1" -->
                    <el-table ref="multipleTable" :data="multipleSelection" tooltip-effect="dark" style="width: 100%">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column label="昵称" width="120">
                            <template slot-scope="scope">{{ scope.row.nickName }}</template>
                        </el-table-column>
                        <el-table-column prop="u0001" label="用户名" width="120">
                        </el-table-column>
                        <el-table-column prop="email" label="邮箱" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <div style="bottom: 20px">
                        <el-pagination @current-change="handleCurrentChange1" :current-page.sync="currentPage4"
                            :page-size="contData1.size" :page-count="contData1.pages" layout="prev, pager, next, jumper"
                            class="pageTool center">
                        </el-pagination>
                        <!-- <el-button @click="toggleSelection([tableData[1], tableData[2]])">确定</el-button> -->
                        <!-- <el-button @click="toggleSelection()">取消选择</el-button> -->
                    </div>
                </div>
            </el-col>
        </el-row>



    </div>
</template>
  
<script>
import axios from "axios";
export default {
    data() {
        return {
            NewModelData: {},
            multipleSelection: [],
            tableData2: [],
            roleData: [],
            fromData: {
                // query:query,
                pageSize: 16,
                pageNum: 1,
                type: "1",
                recordid: '',
            },
            fromData1: {
                // query:query,
                pageSize: 16,
                pageNum: 1,
                type: "1",
                recordid: '',
            },
            currentPage3: 1,
            currentPage4: 1,
            contData: [],
            contData1: [],
            data1: {},
            S_CODE1: {
                label: 'PermissionDescription',
                value: 'U0002',
                tableName: 'U02',
                // condition: 'RouterType',
            }
        }
    },

    methods: {
        getModelType2() {
            axios
                .post("/api/getSelectList", this.S_CODE1, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.options = res.data.data;
                })
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                    console.log(this.multipleSelection)
                    // this.tableData2=rows
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        getUserList() {
            
            this.fromData.u0002 = this.roleData.join(',')
            axios
                .post("/api/UserCenter/UserController/user/GetUserList", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.contData = res.data.data
                    this.tableData2 = res.data.data.list;
                })
        },
        getPerUserList() {
            // console.log(this.$parent.fromData)
            axios
                .post("/api/UserCenter/UserController/user/GetUserList", this.fromData1, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.contData1 = res.data.data
                    this.multipleSelection = res.data.data.list;
                })
        },
        handleCurrentChange() {
            this.fromData.pageNum = this.currentPage3;
            this.getUserList()
        },
        handleCurrentChange1() {
            this.fromData.pageNum = this.currentPage4;
            this.getPerUserList()
        },
    },
    mounted() {
        const targetComponent = this.$parent.$parent;
        if (targetComponent) {
            this.data1 = targetComponent.$data
            // console.log(this.data1.formData.recordid)
            // this.fromData1.recordid=this.data1.formData.recordid
        }
        this.getUserList()
        this.getModelType2()
        // this.getPerUserList()
    }
}
</script>
<style scoped></style>
  