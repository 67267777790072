<template>
    <div>
        <div v-for="(item, index) in codeData">
            <h1 style="margin-top: 10px;">{{ item.dmdescription }}</h1>
            <el-button type="primary" style="float: right;margin-top: -10px;margin-bottom: 5px;"
                @click="newModel">新增</el-button>
            <el-table :data="item.ModelData" border style="width: 100%">
                <el-table-column fixed prop="modelName" label="模块名称" width="150">
                </el-table-column>
                <el-table-column prop="modelUrl" label="模块地址" width="120">
                </el-table-column>
                <el-table-column prop="permissionRole" label="角色" width="120">
                </el-table-column>
                <el-table-column prop="role" label="用户" width="180">
                </el-table-column>
                <el-table-column prop="superId" label="父级" width="120">
                </el-table-column>
                <el-table-column prop="porder" label="排序" width="120">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">授权</el-button>
                        <el-button @click="deleteModel(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog title="提示" :visible.sync="dialogVisible" width="60%" :modal-append-to-body="false">
            <SelectUser ref="SelectUser" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitUser()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="新增" :visible.sync="dialogVisible1" width="400px" :modal-append-to-body="false">
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">名称：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-input v-model="NewModelData.modelName" placeholder="请输入内容"
                        style="margin-left: auto;margin-right: auto;"></el-input>
                </div>
            </div>
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">路径：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-input v-model="NewModelData.modelUrl" placeholder="请输入内容"
                        style="margin-left: auto;margin-right: auto;"></el-input>
                </div>
            </div>
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">分类：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-select v-model="NewModelData.type" placeholder="请选择" style="width: 100%!important;">
                        <el-option v-for="item in options1" :key="item.value" :label="item.dmdescription"
                            :value="item.dmcod">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">角色：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-select v-model="NewModelData.permissionRole" multiple placeholder="请选择"
                        style="width: 100%!important;">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">排序：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-input v-model="NewModelData.porder" placeholder="请输入内容"
                        style="margin-left: auto;margin-right: auto;"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible1 = false">取 消</el-button>
                <el-button type="primary" @click="submitModel()">确 定</el-button>
            </span>
        </el-dialog>

        <el-table :data="sudo" style="width: 100%" hidden="true">
            <el-table-column prop="date" label="日期" width="180">
            </el-table-column>
        </el-table>
        <!-- :before-close="handleClose" -->

        <el-dialog title="提示" :visible.sync="dialogVisible2" width="30%" :modal-append-to-body="false">
            <span>请注意，此操作不可恢复，确认要删除吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="deleteModel1()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import SelectUser from "./SelectUser.vue";
import axios from 'axios';
import Vue from 'vue'
export default {
    components: {
        SelectUser
    },
    data() {
        return {
            modelData: {},
            NewModelData: {},
            codeData: {
                DMClassify: 'RouterType',
            },
            codeData1:{
                DMClassify: 'RouterType',
            },
            tableData: {},
            dialogVisible: false,
            dialogVisible1: false,
            dialogVisible2: false,
            sudo: {},
            formData: {
                recordid: '',
            },
            userList: {},
            dialogData: {},

            options: [{
                value: '选项1',
                label: '黄金糕'
            }],
            options1: [],
            value: '',
            // value1: [],
            S_CODE: {
                DMClassify: 'RouterType',
            },
            S_CODE1: {
                label: 'PermissionDescription',
                value: 'U0002',
                tableName: 'U02',
                // condition: 'RouterType',
            },
            modelDeleteData: {}
        };
    },
    methods: {
        deleteModel(row) {
            this.dialogVisible2 = true;
            this.modelDeleteData.recordid = row.recordid
        },
        openNotification(position = null, color, info, info2) {
            const noti = this.$vs.notification({
                progress: 'auto',
                color,
                position,
                title: info,
                text: info2
            })
        },
        deleteModel1() {
            axios.post("/api/UserCenter/ModelCenter/user/AdminDeleteRouterInfo", this.modelDeleteData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                if (res.data.code == "200") {
                    this.openNotification('top-right', 'success', "提示", "操作成功")

                    this.getModelType()
                    // this.getModelType1()
                    // this.getModelType2()

                    this.dialogVisible2 = false
                }
            })

        },
        getModelType() {
            axios.post("/api/GetCode", this.codeData1, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                if (res.data.code == "200") {
                    this.codeData = res.data.data
                    for (let id = 0; res.data.data[id] != null; id++) {
                        axios.post('/api/UserCenter/ModelCenter/user/AdminGetRouterInfo', res.data.data[id], {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then((res1) => {
                            this.codeData[id].ModelData = res1.data.data
                            this.sudo = [{
                                date: '2016-05-02',
                            }]
                        })
                    }
                }
            })
        },
        handleClick(row) {
            this.dialogVisible = !this.dialogVisible
            this.formData.recordid = row.recordid

            const targetComponent = this.$refs.SelectUser;
            if (targetComponent) {
                targetComponent.$data.value = row.recordid
                targetComponent.getPerUserList()

            }
            // this.formData.type = row.type
        },
        submitUser() {
            this.dialogVisible = false
            const targetComponent = this.$refs.SelectUser;
            if (targetComponent) {
                console.log(targetComponent.multipleSelection)
                this.formData.userList = targetComponent.multipleSelection
                this.userList = targetComponent.multipleSelection
                axios
                    .post(`/api/UserCenter/ModelCenter/user/AdminAddUserPer?recordid=${this.formData.recordid}`, this.userList)
                    .then((res) => {
                        // this.contData = res.data.data;
                        if (res.data.code == "200") {
                            this.openNotification('top-right', 'success', "提示", "操作成功")
                            targetComponent.multipleSelection = {}
                        }
                    })
            }

        },
        getModelType1() {
            axios
                .post("/api/GetCode", this.S_CODE, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.options1 = res.data.data;
                })
        },
        getModelType2() {
            axios
                .post("/api/getSelectList", this.S_CODE1, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.options = res.data.data;
                })
        },
        newModel() {
            this.dialogVisible1 = !this.dialogVisible
        },
        submitModel() {
            this.dialogVisible1 = !this.dialogVisible
            var data = '';
            data = this.NewModelData.permissionRole.join("|");
            this.NewModelData.role = data
            if (this.NewModelData.type == null) {
                this.NewModelData.type = 11
            }
            axios
                .post("/api/UserCenter/ModelCenter/user/AdminAddNewModel", this.NewModelData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    //   this.contData = res.data.data;
                    if (res.data.code == "200") {
                        this.openNotification('top-right', 'success', "提示", "操作成功")
                        this.getModelType()
                        this.dialogVisible1 = false
                        this.NewModelData = {}
                    }
                })
        },
    },
    mounted() {
        this.getModelType()
        this.getModelType1()
        this.getModelType2()
    }

};
</script>

<style scoped>
.lysoft-form-table {
    margin-bottom: 10px;
}
</style>