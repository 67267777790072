<template>
    <div>
        <div class="main-header-line">
            <h1>我的收藏</h1>


            <div class="action-buttons">
                <button class="open-right-area">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-activity">
                        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
                    </svg>
                </button>
                <button class="menu-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-menu">
                        <line x1="3" y1="12" x2="21" y2="12" />
                        <line x1="3" y1="6" x2="21" y2="6" />
                        <line x1="3" y1="18" x2="21" y2="18" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="grid-content bg-purple-dark" style="width: 100%;">
            <div style="display: inline-block ;margin-left: auto;margin-right: auto;">
                <span style="display: inline-block  ;color: white ; margin-left: 25px;" class="area-text">标题或简介：</span>
                <el-input style="width: 200px; display: inline-block " placeholder="请输入内容" prefix-icon="el-icon-search"
                    v-model="fromData.searchText">
                </el-input>
                <el-button type="primary" style="margin-left: 10px;" @click="getCardCount()">查询</el-button>
                <el-button type="primary" style="margin-left: 10px;" @click="reset()">重制</el-button>
            </div>
        </div>
        <div id="apps-card" class="apps-card">
            <div class="app-card" v-for="(item, index) in contData.list">
                <span>
                    {{ item.title }}
                </span>
                <img :src="item.coverImage" class="app-card-image">
                <div class="app-card__subtext">
                    {{ item.description }}
                </div>
                <div class="app-card-buttons">
                    <vs-button v-if="item.deleteFlag == 0" @click="publishStatusChange(item.ar2200,1,item)" icon color="success" border :active="1 == 1" style="background: rgba(var(--vs-color), 1);" >
                        <i class='bx bx-show'></i>
                    </vs-button>
                    <vs-button v-if="item.deleteFlag == 1" @click="publishStatusChange(item.ar2200,0,item)" icon color="warn" border :active="1 == 1" style="background:rgba(var(--vs-color), 1) ;">
                        <i class='bx bx-low-vision' ></i>
                    </vs-button>

                    <vs-button icon border :active="1 == 1" @click="routerGo(item.ar2200)">
                        <i class='bx bxs-edit'></i>
                    </vs-button>
                    <vs-button icon color="danger" border :active="1 == 1" @click="pushCollect(item)"
                        v-if="item.ar2601 == 1">
                        <i class='bx bxs-heart'></i>
                    </vs-button>
                    <vs-button icon color="danger" border :active="0 == 1" @click="pushCollect(item)"
                        v-if="item.ar2601 == 0">
                        <i class='bx bxs-heart'></i>
                    </vs-button>
                    <vs-button icon color="danger" border :active="0 == 1" @click="pushCollect(item)"
                        v-if="item.ar2601 == null">
                        <i class='bx bxs-heart'></i>
                    </vs-button>
                </div>
            </div>
        </div>

        <div class="center">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage3"
                :page-size="contData.size" :page-count="contData.pages" layout="prev, pager, next, jumper"
                class="pageTool center">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import Vue from 'vue'
export default {
    data() {
        return {
            fromData: {
                pageSize: 6,
                pageNum: 1,
                type: "1",
            },
            currentPage3: 1,
            editor: null,
            html: "<p>hello</p>",
            contData: [],
        };
    },
    mounted() {
        this.getCardCount()

    },
    methods: {
        routerGo(num) {
            this.$router.push({
                path: `/publish/${num}`,
            });
        },
        handleCurrentChange() {
            this.fromData.pageNum = this.currentPage3
            axios
                .post("/api/ar/AuthorGetAll", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.contData = res.data.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getCardCount() {
            // this.fromData.query=this.query
            return axios
                .post("/api/ar/AuthorGetAll", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.contData = res.data.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        openNotification(position = null, color, info, info2) {
            const noti = this.$vs.notification({
                progress: 'auto',
                color,
                position,
                title: info,
                text: info2
            })
        },
        publishStatusChange(ar2200,deleteFlag,item2){
            const item ={};
            item.ar2200=ar2200;
            item.deleteFlag=deleteFlag;
            axios
                .post("/api/ar/ArStatusChange", item, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if(res.data.code==200)
                    {
                        this.openNotification('top-right', 'success', "提示", "操作成功")
                        if(item2.deleteFlag==1)
                        {
                            item2.deleteFlag=0;
                        }else{
                            item2.deleteFlag=1;
                        }
                    }
                })
        },
        reset() {
            this.fromData.searchText = ''
            this.getCardCount()
        },
        pushCollect(item) {
            if (item.ar2601 == 1) {
                item.ar2601 = 0
            } else {
                item.ar2601 = 1
            }
            // alert("213")
            axios
                .post("/api/ar/arPushCollect", item, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.openNotification('top-right', 'success', "提示", "操作成功")
                    Vue.set(item, ar2601, res.data.data.ar2601)
                    // item.ar2601=res.data.data.ar2601
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
};

</script>

<style scoped>
.main-header-line-two {
    margin-top: 30px !important;
}

.pageTool {
    /* margin-left: auto!important;
      margin-right: auto!important; */
    /* margin:0 auto !important; */
    margin-top: 20px;
    text-align: center !important;
    /* color: s=salmon; */
}
</style>