<template>
    <div>
        <button @click="editorChange()">编辑</button>
        <button @click="editorSave()">保存</button>
        <el-row @click="editorChange()" v-if="editor == 0">
            <el-col :span="12">
                <div class="profile-photo-wrapper" style="margin-top: 20px; margin-left: 60px;">
                    <img :src="userData.userImage">
                </div>
                <div class="grid-content bg-purple">
                    <div class="demo-input-suffix">
                        <span style="display: inline-block ;color: #FFFFFF ;" class="area-text">昵称：</span>
                        <div style="display: inline-block ;width: 70%!important;">
                            <!-- 属性方式： -->
                            <span style="display: inline-block" class="area-text">{{ userData.nickName }}</span>
                            <!-- <vs-input border class="text_user" v-model="input" style="color:#FFFFFF !important;" placeholder="Name" /> -->
                            <!-- <el-input  v-model="input" placeholder="请输入内容"></el-input> -->
                        </div>
                        <br>
                    </div>
                    <div class="demo-input-suffix">
                        <span style="display: inline-block ;color: #FFFFFF ;" class="area-text">年龄：</span>
                        <div style="display: inline-block;width: 70%!important;">
                            <span style="display: inline-block" class="area-text">{{ userData.age }}</span>
                            <!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
                        </div>
                        <br>
                    </div>
                    <!-- <span style="display: inline-block ;color: #FFFFFF;" class="area-text">邮箱：</span>
                    <div style="display: inline-block;width: 70%!important;">
                        <span style="display: inline-block" class="area-text">{{ userData.email }}</span>
                    </div>
                    <br> -->
                </div>
            </el-col>

            <el-col :span="12">



                <span style="display: inline-block ;color: #FFFFFF;" class="area-text">性别：</span>
                <div style="display: inline-block;width: 70%!important;">
                    <span style="display: inline-block" class="area-text">{{ userData.gender }}</span>
                    <!-- <el-input label="nikankan" style="color:#FFFFFF !important;" v-model="input"
                        placeholder="请输入内容"></el-input> -->
                    <br>
                </div>
                <div class="grid-content bg-purple-light"></div>

                <!-- <span style="display: inline-block ;color: #FFFFFF;" class="area-text">账号：</span>
                <div style="display: inline-block;width: 70%!important;">
                    <span style="display: inline-block" class="area-text">{{ userData.phone }}</span>
                    <br>
                </div> -->
                <br>
                <span style="display: inline-block ;color: #FFFFFF;" class="area-text">电话：</span>
                <div style="display: inline-block;width: 70%!important;">
                    <span style="display: inline-block" class="area-text">{{ userData.phone }}</span>
                    <!-- <el-input label="nikankan" style="color:#FFFFFF !important;" v-model="input"
                        placeholder="请输入内容"></el-input> -->
                    <br>
                </div>
            </el-col>
            <div style="display: inline-block;width: 90%!important;margin-top: 20px; margin-left: auto;margin-right: auto;">
                <span style="display: inline-block ;color: #FFFFFF;" class="area-text11">个人简介：</span>
                <!-- <el-input type="textarea" placeholder="请输入内容" v-model="textarea" maxlength="200" show-word-limit> -->
                <span style="display: inline-block" class="area-text">{{ userData.userDescription }}</span>
                <!-- </el-input> -->
                <br>
            </div>

            <div style="display: inline-block;width: 70%!important;">
                <span style="display: inline-block ;color: #FFFFFF;" class="area-text">邮箱：</span>
                <span style="display: inline-block" class="area-text">{{ userData.email }}</span>
            </div>
            <br>
        </el-row>

        <el-row @click="editorChange()" v-if="editor == 1">
            <el-col :span="12">
                <div class="profile-photo-wrapper" style="margin-top: 20px; margin-left: 60px;">
                    <!-- <img :src="userData.userImage"> -->
                    <el-upload class="avatar-uploader" action="#" :show-file-list="false" :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload" :http-request="handleFileUpload">
                        <img v-if="userData.userImage" :src="userData.userImage" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div class="grid-content bg-purple">
                    <div class="demo-input-suffix">
                        <span style="display: inline-block ;color: #FFFFFF ;" class="area-text">昵称：</span>
                        <div style="display: inline-block ;width: 70%!important;">
                            <!-- 属性方式： -->
                            <!-- <span style="display: inline-block">sad</span> -->
                            <!-- <vs-input border class="text_user" v-model="input" style="color:#FFFFFF !important;" placeholder="Name" /> -->
                            <el-input v-model="userData.nickName" placeholder="请输入内容"></el-input>
                        </div>
                        <br>
                    </div>
                    <div class="demo-input-suffix">
                        <span style="display: inline-block ;color: #FFFFFF ;" class="area-text">年龄：</span>
                        <div style="display: inline-block;width: 70%!important;">
                            <el-input v-model="userData.age" placeholder="请输入内容"></el-input>
                        </div>
                        <br>
                    </div>
                    <!-- <span style="display: inline-block ;color: #FFFFFF;" class="area-text">邮箱：</span>
                    <div style="display: inline-block;width: 70%!important;">
                        <el-input label="nikankan" style="color:#FFFFFF !important;" v-model="userData.email"
                            placeholder="请输入内容"></el-input>
                    </div>
                    <br> -->
                </div>
            </el-col>

            <el-col :span="12">
                <span style="display: inline-block ;color: #FFFFFF;" class="area-text">性别：</span>
                <div style="display: inline-block;width: 70%!important;">
                    <el-select v-model="userData.gender" placeholder="请选择">
                        <el-option style="width: 100%;" v-for="item in options" :key="item.value" :label="item.label"
                            v-bind="userData.gender" :value="item.value">
                        </el-option>
                    </el-select>
                    <br>
                </div>
                <div class="grid-content bg-purple-light"></div>

                <!-- <span style="display: inline-block ;color: #FFFFFF;" class="area-text">账号：</span>
                <div style="display: inline-block;width: 70%!important;">
                    <el-input label="nikankan" style="color:#FFFFFF !important;" v-model="userData.phone"
                        placeholder="请输入内容" disabled="true"></el-input>
                    <br>
                </div> -->
                <!-- <br> -->
                <span style="display: inline-block ;color: #FFFFFF;" class="area-text">电话：</span>
                <div style="display: inline-block;width: 70%!important;">
                    <el-input label="nikankan" style="color:#FFFFFF !important;" v-model="userData.phone"
                        placeholder="请输入内容"></el-input>
                    <br>
                </div>
            </el-col>

            <div style="display: inline-block;width: 90%!important;margin-top: 20px; margin-left: auto;margin-right: auto;">
                <span style="display: inline-block ;color: #FFFFFF;" class="area-text11">个人简介：</span>
                <el-input type="textarea" placeholder="请输入内容" v-model="userData.userDescription" maxlength="200"
                    show-word-limit>
                </el-input>
                <br>
            </div>

            <div style="display: inline-block;width: 35%!important;">
                <span style="display: inline-block ;color: #FFFFFF;" class="area-text">邮箱：</span>
                <el-input label="nikankan" style="color:#FFFFFF !important;" v-model="userData.email"
                    placeholder="请输入内容"></el-input>
                <div class="center">
                    <vs-button @click="editorSaveEmail()">
                        提交
                    </vs-button>
                    <vs-dialog v-model="active" prevent-close="false">
                        <template #header>
                            <h4 class="not-margin">
                                Welcome to <b>Daeeeee</b>
                            </h4>
                        </template>

                        <div class="con-form">
                            <!-- <span style="display: inline-block ;color: #FFFFFF;" class="area-text">邮箱：</span> -->
                            <el-input label="nikankan" style="color:#FFFFFF !important;" v-model="codeData.code"
                                placeholder="请输入验证码"></el-input>
                        </div>

                        <template #footer>
                            <div class="footer-dialog">
                                <vs-button block @click="saveEmail()">
                                    提交
                                </vs-button>

                                <div class="new">
                                    没收到验证码? 查看草稿箱或 <a @click="editorSaveEmail1()" style="color: blue;">重新发送</a>
                                </div>
                            </div>
                        </template>
                    </vs-dialog>
                </div>
                <br>
            </div>
            <!-- <el-button type="primary" round @click="editorSaveEmail()">提交</el-button> -->


            <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
                <span>这是一段信息</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                </span>
            </el-dialog> -->




        </el-row>


    </div>
    <!-- <div class="center content-inputs">
      <vs-input border v-model="value" placeholder="Name" />

      <vs-input color="#7d33ff" border type="password" v-model="value2" placeholder="Password">
        <template #icon>
          <i class='bx bx-lock-open-alt'></i>
        </template>
      </vs-input>

      <vs-input border warn type="email" icon-after v-model="value3" label-placeholder="Address">
        <template #icon>
          <i class='bx bxl-bitcoin'></i>
        </template>
      </vs-input>
    </div> -->
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            options: [{
                value: '1',
                label: '男'
            }, {
                value: '0',
                label: '女'
            }],
            userData: {},
            value: '',
            editor: 0,
            input: '',
            dialogVisible: false,
            active: false,
            codeData: {},
        };
    },
    methods: {
        editorChange() {
            if (this.editor == 0) {
                this.editor = 1
            } else { this.editor = 0 }
        },
        getUserInfo() {
            axios.post('/api/us/User/getUserInfo', this.fromData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                this.userData = res.data.data;
                try {
                    if (this.userData.gender){
                    if (this.userData.gender == 0) {
                    this.userData.gender = '女';
                } else {
                    this.userData.gender = '男';
                }
                }
                } catch (error) {
                    // console.log(error)
                }


            })
        },
        getUserData() {
            const targetComponent1 = this.$parent.$parent;
            if (targetComponent1 && targetComponent1.$data.userData.u0000 != null) {
                this.userData = targetComponent1.$data.userData;
                if (this.userData.gender == 0) {
                    this.userData.gender = '女';
                } else {
                    this.userData.gender = '男';
                }
            } else {
                this.getUserInfo();
            }

        },
        editorSave() {
            if (this.userData.gender === '女') {
                this.userData.gender = 0;
            } else if (this.userData.gender === '男') {
                this.userData.gender = 1;
            }
            axios.post('/api/us/User/UpdateUserInfo', this.userData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                if (this.editor == 0) {
                    this.editor = 1
                } else { this.editor = 0 }
                if(res.data.code==="200"){
                    this.openNotification('top-right', 'success', "提示", "操作成功！")}
            })
        },
        editorSaveEmail() {
            axios.post('/api/us/User/SendUserEmail', this.userData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                //     if (this.editor == 0) {
                //     this.editor = 1
                // } else { this.editor = 0 }
                if (res.data.code === "200") {
                    this.active = !this.active
                    this.openNotification('top-right', 'primary', "提示", "操作成功！")

                } else {
                    this.openNotification('top-right', 'danger', "提示", res.data.message)
                }
            })
        },
        editorSaveEmail1() {
            axios.post('/api/us/User/SendUserEmail', this.userData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                //     if (this.editor == 0) {
                //     this.editor = 1
                // } else { this.editor = 0 }
                if (res.data.code === "200") {
                    this.openNotification('top-right', 'primary', "提示", "操作成功！")

                } else {
                    this.openNotification('top-right', 'danger', "提示", res.data.message)
                }
            })
        },
        saveEmail() {
            axios.post('/api/us/User/UpdateUserEmail', this.codeData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                //     if (this.editor == 0) {
                //     this.editor = 1
                // } else { this.editor = 0 }
                if (res.data.code === "200") {
                    this.openNotification('top-right', 'primary', "提示", "操作成功！")
                    this.active = !this.active
                    if (res.data.token) {
                        localStorage.daeeeee_token = res.data.token
                        localStorage.setItem("daeeeee_token", res.data.token)
                    }
                } else {
                    this.openNotification('top-right', 'danger', "提示", res.data.message)
                }
            })
        },
        openNotification(position = null, color, info, info2) {
            const noti = this.$vs.notification({
                progress: 'auto',
                color,
                position,
                title: info,
                text: info2
            })
        },
        handleFileUpload(file) {
            // const file = event.target.files[0]; // 获取选择的文件
            const formData = new FormData(); // 创建FormData对象
            formData.append('file', file.file); // 将文件添加到FormData对象中
            axios.post('/api/ar/arUploadImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // 设置请求头为multipart/form-data
                }
            })
                .then(response => {
                    // 文件上传成功的处理逻辑
                    this.userData.userImage=response.data.data.url
                })
        }

    },
    mounted() {
        this.getUserData();
    }
};
</script>
  
<style scoped>
/* .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  } */
.area-text {
    margin-top: 20px;
    height: 40px;
    line-height: 40px;
    color: white;
}

.el-textarea__inner {
    margin-top: 20px;
    height: 40px;
    line-height: 40px;
    color: white;
}

.el-input__inner {
    width: 100% !important;
}

.el-input {
    width: 100% !important;
}

.el-select {
    width: 100% !important;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}

.avatar {
    width: 80px;
    height: 80px;
    display: block;
}
</style>
  