<template>
  <div>
    <div class="header">
      <!-- <div class="menu-circle"></div> -->
      <div class="header-menu">
        <router-link class="menu-link " :class="{'is-active' : isActive === 999}" @click.native="headerChange(999)"  to="/">首页</router-link>

        <router-link class="menu-link" :class="{'is-active' : isActive === index}" @click.native="headerChange(index)" v-for="(item, index) in routerData" :to="item.modelUrl" :key="index">{{item.modelName}}</router-link>

        <!-- <router-link class="menu-link notify" to="/publish">发布</router-link> -->
        <!-- <router-link class="menu-link" to="#" hidden="true">Discover</router-link> -->
        <!-- <router-link class="menu-link notify" to="#" hidden="true">Market</router-link> -->

        <!-- <router-link :class="activeIndex === index ? 'is-active':''" class="menu-link"
            @click="addClass(index)"
            v-for="(item, index) in items"
            :key="index"
            :to="item.url">
            {{item.title}}</router-link> -->
      </div>

      <div class="search-bar">
        <input type="text" placeholder="Search" @keydown.enter="search" v-model="searchText" @focus="addWideClass"
          @blur="removeWideClass">
      </div>
      <!-- @select="handleSelect"
      :default-active="activeIndex2" -->
      <div class="header-profile">
        <el-menu mode="horizontal" background-color="transparent" v-if="isLogin" >
          <el-menu-item index="1" class="menu-link" @click.native="headerChange(998)" @click="ToNewPage('/notify')">
            <div class="notification">
              <span class="notification-number" v-if="count != 0">{{ count }}</span>
              <svg viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" class="feather feather-bell">
                <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 01-3.46 0" />
              </svg>
            </div>
          </el-menu-item>
          <el-menu-item index="2" class="menu-link" @click.native="headerChange(997)"  hidden="true">
            <div class="notification">
            <svg viewBox="0 0 512 512" fill="currentColor">
              <path
                d="M448.773 235.551A135.893 135.893 0 00451 211c0-74.443-60.557-135-135-135-47.52 0-91.567 25.313-115.766 65.537-32.666-10.59-66.182-6.049-93.794 12.979-27.612 19.013-44.092 49.116-45.425 82.031C24.716 253.788 0 290.497 0 331c0 7.031 1.703 13.887 3.006 20.537l.015.015C12.719 400.492 56.034 436 106 436h300c57.891 0 106-47.109 106-105 0-40.942-25.053-77.798-63.227-95.449z" />
            </svg>
          </div>
          </el-menu-item>
          

          <el-submenu index="3" class="menu-link" @click.native="headerChange(996)" style="display: inline-flex;">
            <template slot="title">
              <img class="lysoft-profile-img" :src="userData.userImage" alt="">
            </template>
            <el-menu-item index="3-1" class="menu-link" @click.native="headerChange(995)" @click="ToNewPage('/UserCenter/home')" >个人中心</el-menu-item>
            <el-menu-item index="3-2" class="menu-link" @click.native="headerChange(994)" @click="ToNewPage('/UserFront/home')">创作中心</el-menu-item>
            <el-menu-item index="3-3" class="menu-link" @click.native="headerChange(993)" @click="logout">退出登陆</el-menu-item>
          </el-submenu>
        </el-menu>
        <div class="header-menu"><router-link class="menu-link navigation-front" to="/login"
            v-if="!isLogin">登陆</router-link></div>
      </div>
            <!-- <el-submenu index="2-4">
      <template slot="title">选项4</template>
      <el-menu-item index="2-4-1">选项1</el-menu-item>
      <el-menu-item index="2-4-2">选项2</el-menu-item>
      <el-menu-item index="2-4-3">选项3</el-menu-item>
    </el-submenu> -->
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Vue from 'vue'
export default {
  data() {
    return {
      activeIndex: 0,
      searchText: '',
      localData: '',
      content: '',
      fromData: {},
      PermissionData: {
        type:1,
      },
      isActive:999,
      userData:{},
      routerData:[],
        count: 0,
      imageUrl: '',
      isLogin: false,
    };
  },
  methods: {
    search() {
      this.$router.push({
        path: '/SearchPage',
        query: {
          searchText: this.searchText
        }
      })
    },
    ToNewPage(url){
      this.$router.push({
        path: url,
        // query: {
        //   searchText: this.searchText
        // }
      })
    },
    headerChange(index){
      // document.querySelector('.menu-link').classList.remove('is-active');
      this.isActive=index;
    },
    addClass(index) {
      this.activeIndex = index;
    },
    afterLogin(U0000) {
      this.isLogin = true;
      this.getUserInfo();
      this.getUserPermissionInfo();
    },
    addWideClass() {
      // 在此处添加类名或通过修改数据来控制样式
      this.$nextTick(() => {
        // 使用$nextTick以确保DOM更新后再进行操作
        document.querySelector('.header').classList.add('wide');
      });
    },
    removeWideClass() {
      // 在此处移除类名或通过修改数据来控制样式
      this.$nextTick(() => {
        // 使用$nextTick以确保DOM更新后再进行操作
        document.querySelector('.header').classList.remove('wide');
      });
    },
    refreshPage() {
      location.reload();
    },
    openNotification(position = null, color, info, info2) {
      const noti = this.$vs.notification({
        progress: 'auto',
        color,
        position,
        title: info,
        text: info2
      })
    },
    logout() {
      this.isLogin = false
      localStorage.daeeeee_token = "logout"
      window.location.reload()
    },
    getUserInfo() {
      axios.post('/api/us/User/getUserInfo', this.fromData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => { this.userData=res.data.data;
        const targetComponent1 = this.$parent;
            if (targetComponent1) {
              targetComponent1.afterLogin(res.data.data);
            }
      })
    },
    getUserPermissionInfo(){
      axios.post('/api/UserCenter/RoleCenter/user/getUserPermission', this.PermissionData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => { 
        for (let id = 0; res.data.data.data[id] != null; id++) {
        Vue.set(this.routerData,id,res.data.data.data[id])
      }
      })
    }
  },
  mounted() {
    let _this = this;
    if (window.localStorage.getItem('daeeeee_token')) {
      if (window.localStorage.getItem('daeeeee_token') === "logout") {
        _this.isLogin = false
      } else if (window.localStorage.getItem('daeeeee_token') === "nologin") {
        // _this.openNotification('top-center', 'danger', "未登陆", "请先登陆")
        // this.$router.push('/login')
      }
      else {
        _this.isLogin = true
        _this.getUserInfo();
        _this.getUserPermissionInfo();
      }
    } else {
      _this.isLogin = false
      // _this.openNotification('top-center', 'danger', "登录过期", "请重新登陆")
      // window.location.reload()
    }



  },
};
</script>

<style >

.lysoft-profile-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    border: 2px solid var(--theme-color);
    margin-left: 15px;
    margin-top: 15px;
}
.el-submenu__title{
  display: flex!important;
}
.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
  /* position: absolute!important;; */
  line-height: 60px;
}
.el-menu.el-menu--horizontal{
  border-bottom: 0px!important;
}
.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom:2px solid white !important;
    /* color: #303133; */
}
.el-submenu.is-active .el-submenu__title {
    border-bottom-color: #ffffff;
}

.search-bar {
  margin-left: auto;
  /* margin-right: auto; */
}

.user-menu {
  margin-left: 5px;
}

.el-menu--horizontal>.el-menu-item {
  float: left;
  height: 60px;
  line-height: 60px;
  margin: 0;
  border-bottom: 2px solid transparent;

}

.el-menu.el-menu--horizontal {
  border-bottom: solid 1px transparent;
  /* border-bottom-width: 1px; */
  /* border-bottom-style: solid; */
  /* border-bottom-color: rgb(230, 230, 230); */
}

.el-menu--horizontal>.el-menu-item.is-active {
  color: var(--theme-color);
  border-bottom: 2px solid var(--theme-color);
}

.is-active {
  border-bottom: 2px solid var(--theme-color);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--theme-color);
  /* color: var(--theme-color); */
  color: #ffffff;
}

.notification-number {
  margin-top: 13px;
}
.navigation-front {
  padding: 20px 30px;
  text-decoration: none;
  color: var(--inactive-color);
  border-bottom: 2px solid transparent;
  transition: 0.3s;
}
</style>
