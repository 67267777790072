<template>
  <div class="app-container">
    <div class="app-left">
      <button class="close-menu">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      <div class="app-logo">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-bar-chart-2">
          <line x1="18" y1="20" x2="18" y2="10" />
          <line x1="12" y1="20" x2="12" y2="4" />
          <line x1="6" y1="20" x2="6" y2="14" />
        </svg>
        <span>创作中心</span>
      </div>

      <ul class="nav-list">
        <!-- 动态生成导航栏 -->
        <li class="nav-list-item active" v-for="(item, index) in routerData" :key="item.recordid">
          <router-link class="nav-list-link" :to="item.modelUrl">
            {{ item.modelName }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="app-main">
      <router-view />
    </div>

    <div class="app-right">
      <button class="close-right">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      <div class="profile-box">
        <div class="profile-photo-wrapper">
          <img :src="userData.userImage" alt="profile">
        </div>
        <p class="profile-text">{{ this.userData.nickName }}</p>
        <p class="profile-subtext">权限角色：{{ this.userData.permissionDescription }}</p>
      </div>
      <div class="app-right-content">
        <div class="app-right-section" v-if="false">
          <div class="app-right-section-header">
            <h2>信息</h2>
            <span class="notification-active">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-message-square">
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
              </svg>
            </span>
          </div>
          <div class="message-line">
            <img
              src="https://images.unsplash.com/photo-1562159278-1253a58da141?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzB8fHBvcnRyYWl0JTIwbWFufGVufDB8MHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
              alt="profile">
            <div class="message-text-wrapper">
              <p class="message-text">Eric Clampton</p>
              <p class="message-subtext">Have you planned any deadline for this?</p>
            </div>
          </div>
          <div class="message-line">
            <img
              src="https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80"
              alt="profile">
            <div class="message-text-wrapper">
              <p class="message-text">Jess Flax</p>
              <p class="message-subtext">Can we schedule another meeting for next thursday?</p>
            </div>
          </div>
          <div class="message-line">
            <img
              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80"
              alt="profile">
            <div class="message-text-wrapper">
              <p class="message-text">Pam Halpert</p>
              <p class="message-subtext">The candidate has been shorlisted.</p>
            </div>
          </div>
        </div>
        <div class="app-right-section">
          <div class="app-right-section-header">
            <h2>通知</h2>
            <span class="notification-active">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-bell">
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
                <path d="M13.73 21a2 2 0 0 1-3.46 0" />
              </svg>
            </span>
          </div>

          <!-- 生成通知 -->
          <div class="activity-line" v-for="(item, index) in tzData" :key="tzData.tz0200">
            <span class="activity-icon warning">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-alert-circle">
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="8" x2="12" y2="12" />
                <line x1="12" y1="16" x2="12.01" y2="16" />
              </svg>
            </span>
            <div class="activity-text-wrapper">
              <p class="activity-text">{{ item.tz0101 }}</p>
              <a class="activity-link" :href="item.tz0103" v-if="item.tz0103 != null">{{ item.tz0102 }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserFront from "../assets/js/UserFront.js";
import axios from 'axios';
import Vue from 'vue'
export default {
  components: {
    // UserCard
    // Cards,
  },
  data() {
    return {
      userData: {},
      tzData: {},
      routerData: {},
      PermissionData: {
        type: 3,
      },
      queryInfo: {
        pageSize: 9,
        pageNum: 1,
      },
    };
  },
  methods: {
    getUserInfo() {
      axios.post('/api/us/User/getUserInfo', this.fromData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => { this.userData = res.data.data; })
    },
    getTz() {
      return axios
        .post("/api/tz/tzGetAll", this.queryInfo, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.tzData = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getUserPermissionInfo() {
      axios.post('/api/UserCenter/RoleCenter/user/getUserPermission', this.PermissionData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        for (let id = 0; res.data.data.data[id] != null; id++) {
          Vue.set(this.routerData, id, res.data.data.data[id])
        }
      })
    }
  },
  mounted() {
    this.getUserInfo();
    this.getTz();
    this.getUserPermissionInfo();


    
  }
};
</script>
  
<style scoped>
/* @import  ("../assets/css/googleCss2.css"); */
/* @import  ("../assets/css/UserFront.css"); */
@import "../assets/css/UserFront.css";
@import "../assets/css/googleCss2.css";
</style>
  