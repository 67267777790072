<template>
  <div id="app" class="app">
    <Navigation ref="Navigation"/>

    <div class="wrapper">
      <!-- <LeftSide/> -->

      <div class="main-container ">

        <router-view />
        <!-- <div class="content-wrapper">
          <router-view />

          <div class="content-section">
            <cards />
          </div>

          <Footer />
        </div> -->

        
        <div class="overlay-app"></div>
        
      </div>
      <!-- <el-popover
    placement="bottom"
    title="标题"
    width="200"
    trigger="manual"
    content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
    v-model="visible">
    <el-button slot="reference" @click="visible = !visible">手动激活</el-button>
  </el-popover> -->
    </div>
    
   
  </div>
</template>

<script>
import 'boxicons';
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";
import LeftSide from "./components/LeftSide.vue";
// import Cards from "./components/Cards.vue";

export default {
  components: {
    Navigation,
    Footer,
    LeftSide,
    // Cards,
  },  
  data() {
    return {
      fromData:{},
      userData:{},
      searchText:'',
      visible:false,
      isLogin:false,
    };
  },
  methods:{
    afterLogin(userData){
this.userData=userData
if(userData){
  this.isLogin=true
}
    }
  }
};
</script>

<style>
#app {
  min-height: 100vh;
  display: flex;
  width: 100%!important;
  flex-direction: column;
}
.app{
  max-width: 2560px!important;;
}

.main-container {
  flex-grow: 1;
}

.content {
  /* 设置内容区域为自动增长，并且允许内容溢出 */
  flex-grow: 1;
  overflow-y: auto;
}

.Footer {
  /* 固定 Footer 在底部 */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
