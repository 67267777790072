import { render, staticRenderFns } from "./RoleCenter.vue?vue&type=template&id=42ce614e&scoped=true"
import script from "./RoleCenter.vue?vue&type=script&lang=js"
export * from "./RoleCenter.vue?vue&type=script&lang=js"
import style0 from "./RoleCenter.vue?vue&type=style&index=0&id=42ce614e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42ce614e",
  null
  
)

export default component.exports