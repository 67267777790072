<template>
  <div class="content-wrapper app-main">
    <h1>搜索结果</h1>
    <div class="content-section">
      <div id="apps-card" class="apps-card">
        <div class="app-card" v-for="(item, index) in contData.list">
          <span>
            {{ item.title }}
          </span>
          <img :src="item.coverImage" class="app-card-image">
          <div class="app-card__subtext">
            {{ item.description }}
          </div>
          <div class="app-card-buttons">
            <vs-button icon border :active="1 == 1" @click="routerGo(item.ar2200)">
              <i class='bx bx-show'></i>
            </vs-button>
            <vs-button icon color="danger" border :active="1 == 1" @click="pushCollect(item)" v-if="item.ar2601 == 1">
              <i class='bx bxs-heart'></i>
            </vs-button>
            <vs-button icon color="danger" border :active="0 == 1" @click="pushCollect(item)"
              v-if="item.ar2601 == 0 || item.ar2601 == null">
              <i class='bx bxs-heart'></i>
            </vs-button>
          </div>
        </div>
      </div>

      <!-- </div> -->
    </div>
    <div class="center">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage3" :page-size="contData.size"
        :page-count="contData.pages" layout="prev, pager, next, jumper" class="pageTool center">
      </el-pagination>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";


// import '../assets/home/assets/js/-Filterable-Cards--Filterable-Cards.js'

export default {
  data() {
    return {
      fromData: {
        pageSize: 9,
        pageNum: 1,
      },
      editor: null,
      html: "<p>hello</p>",
      contData: [],
      searchText: '',
      currentPage3: 1,
    };
  },
  watch: {
    '$route.query.searchText'(old, now) {
      this.fromData.searchText = old
      this.getCardCount()
    },
  },
  mounted() {
    this.fromData.searchText = this.$route.query.searchText
    this.getCardCount()
  },
  methods: {
    routerGo(num) {
      this.$router.push({
        path: `/contentShow/${num}`,
      });
    },
    handleCurrentChange() {
      this.fromData.pageNum = this.currentPage3
      axios
        .post("/api/ar/arGetAll", this.fromData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.contData = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    openNotification(position = null, color, info, info2) {
      const noti = this.$vs.notification({
        progress: 'auto',
        color,
        position,
        title: info,
        text: info2
      })
    },
    getCardCount() {
      return axios
        .post("/api/ar/arGetAll", this.fromData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.contData = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    routerGo(num) {
      this.$router.push({
        path: `/contentShow/${num}`,
      });
    },
    pushCollect(item) {
      if (item.ar2601 == 1) {
        item.ar2601 = 0
      } else {
        item.ar2601 = 1
      }
      // alert("213")
      axios
        .post("/api/ar/arPushCollect", item, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.openNotification('top-right', 'success', "提示", "操作成功")
          Vue.set(item, ar2601, res.data.data.ar2601)
          // item.ar2601=res.data.data.ar2601
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped></style>
