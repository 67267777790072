<template>
    <div id="UserCenter" class="app-container">
        <div class="app-left">
            <button class="close-menu">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </button>
            <div class="app-logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-bar-chart-2">
                    <line x1="18" y1="20" x2="18" y2="10" />
                    <line x1="12" y1="20" x2="12" y2="4" />
                    <line x1="6" y1="20" x2="6" y2="14" />
                </svg>
                <span>个人中心</span>
            </div>

            <ul class="nav-list">
                <!-- 动态生成导航栏 -->
                <li class="nav-list-item active" v-for="(item, index) in routerData" :key="item.recordid">
                    <!-- <a class="nav-list-link"  :href="item.modelUrl"> -->
                    <router-link class="nav-list-link" :to="item.modelUrl">
                        <!-- <router-link class="nav-list-link"  to="/"> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-columns">
                            <path
                                d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18" />
                        </svg> -->
                        {{ item.modelName }}
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="app-main">

            <!-- <UserCard /> -->
            <router-view />
        </div>

        <div class="app-right">
            <button class="close-right">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </button>
            <div class="profile-box">
                <div class="profile-photo-wrapper">
                    <img :src="userData.userImage">
                </div>
                <p class="profile-text">{{ this.userData.nickName }}</p>
                <p class="profile-subtext">权限角色：{{ this.userData.permissionDescription }}</p>
            </div>
            <div class="app-right-content">
                <div class="app-right-section" v-if="false">
                    <div class="app-right-section-header">
                        <h2>信息</h2>
                        <span class="notification-active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-message-square">
                                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                            </svg>
                        </span>
                    </div>
                    <div class="message-line">
                        <img src="https://images.unsplash.com/photo-1562159278-1253a58da141?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzB8fHBvcnRyYWl0JTIwbWFufGVufDB8MHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
                            alt="profile">
                        <div class="message-text-wrapper">
                            <p class="message-text">Eric Clampton</p>
                            <p class="message-subtext">Have you planned any deadline for this?</p>
                        </div>
                    </div>
                    <div class="message-line">
                        <img src="https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80"
                            alt="profile">
                        <div class="message-text-wrapper">
                            <p class="message-text">Jess Flax</p>
                            <p class="message-subtext">Can we schedule another meeting for next thursday?</p>
                        </div>
                    </div>
                    <div class="message-line">
                        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80"
                            alt="profile">
                        <div class="message-text-wrapper">
                            <p class="message-text">Pam Halpert</p>
                            <p class="message-subtext">The candidate has been shorlisted.</p>
                        </div>
                    </div>
                </div>
                <div class="app-right-section">
                    <div class="app-right-section-header">
                        <h2>通知</h2>
                        <span class="notification-active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-bell">
                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
                                <path d="M13.73 21a2 2 0 0 1-3.46 0" />
                            </svg>
                        </span>
                    </div>

                    <!-- 生成通知 -->
                    <div class="activity-line" v-for="(item, index) in tzData" :key="tzData.tz0200">
                        <!-- 绿色 applicant 红 warning 黄色close 橘黄 draft-->
                        <span class="activity-icon applicant">
                            <i class='bx bx-bell'></i>
                        </span>
                        <div class="activity-text-wrapper">
                            <p class="activity-text" style="color: white;">{{ item.tz0101 }}</p>
                            <a class="activity-link" :href="item.tz0103" target="_blank" v-if="item.tz0103 != null">{{
                                item.tz0102 }}</a>
                        </div>
                    </div>
                    <!-- <div class="activity-line">
                        <span class="activity-icon applicant">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-file-plus">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                                <polyline points="14 2 14 8 20 8" />
                                <line x1="12" y1="18" x2="12" y2="12" />
                                <line x1="9" y1="15" x2="15" y2="15" />
                            </svg>
                        </span>
                        <div class="activity-text-wrapper">
                            <p class="activity-text">There are <strong>3 new applications</strong> for <strong>UI
                                    Developer</strong></p>
                        </div>
                    </div>
                    <div class="activity-line">
                        <span class="activity-icon close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-x-circle">
                                <circle cx="12" cy="12" r="10" />
                                <line x1="15" y1="9" x2="9" y2="15" />
                                <line x1="9" y1="9" x2="15" y2="15" />
                            </svg>
                        </span>
                        <div class="activity-text-wrapper">
                            <p class="activity-text">Your teammate, <strong>Wade Wilson</strong> has closed the job post of
                                <strong>IOS Developer</strong>
                            </p>
                        </div>
                    </div>
                    <div class="activity-line">
                        <span class="activity-icon applicant">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-file-plus">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                                <polyline points="14 2 14 8 20 8" />
                                <line x1="12" y1="18" x2="12" y2="12" />
                                <line x1="9" y1="15" x2="15" y2="15" />
                            </svg>
                        </span>
                        <div class="activity-text-wrapper">
                            <p class="activity-text">There are <strong>4 new applications</strong> for <strong>Front-End
                                    Developer</strong></p>
                        </div>
                    </div>
                    <div class="activity-line">
                        <span class="activity-icon applicant">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-file-plus">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                                <polyline points="14 2 14 8 20 8" />
                                <line x1="12" y1="18" x2="12" y2="12" />
                                <line x1="9" y1="15" x2="15" y2="15" />
                            </svg>
                        </span>
                        <div class="activity-text-wrapper">
                            <p class="activity-text">There are <strong>2 new applications</strong> for <strong>Design
                                    Lead</strong></p>
                        </div>
                    </div>
                    <div class="activity-line">
                        <span class="activity-icon close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-x-circle">
                                <circle cx="12" cy="12" r="10" />
                                <line x1="15" y1="9" x2="9" y2="15" />
                                <line x1="9" y1="9" x2="15" y2="15" />
                            </svg>
                        </span>
                        <div class="activity-text-wrapper">
                            <p class="activity-text">Your teammate, <strong>Wade Wilson</strong> has closed the job post of
                                <strong>Back-End Developer</strong>
                            </p>
                        </div>
                    </div>
                    <div class="activity-line">
                        <span class="activity-icon draft">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-file-minus">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                                <polyline points="14 2 14 8 20 8" />
                                <line x1="9" y1="15" x2="15" y2="15" />
                            </svg>
                        </span>
                        <div class="activity-text-wrapper">
                            <p class="activity-text">You have drafted a job post for <strong>HR Specialist</strong></p>
                            <a href="#" class="activity-link">Complete Now</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import UserFront from "../assets/js/UserFront.js";
import Vue from 'vue'
import UserCard from "./UserCard.vue";
import Cards from "./Cards.vue";
import axios from 'axios';
export default {
    components: {
        UserCard,
        Cards,
    },
    data() {
        return {
            userData: {},
            tzData: {},
            routerData: {},
            PermissionData: {
                type: 2,
            },
            queryInfo: {
                pageSize: 9,
                pageNum: 1,
            },
        };
    },
    methods: {
        getUserInfo() {
            axios.post('/api/us/User/getUserInfo', this.fromData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => { this.userData = res.data.data; })
        },
        getTz() {
            return axios
                .post("/api/tz/tzGetAll", this.queryInfo, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.tzData = res.data.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getUserPermissionInfo() {
            axios.post('/api/UserCenter/RoleCenter/user/getUserPermission', this.PermissionData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                for (let id = 0; res.data.data.data[id] != null; id++) {
                    Vue.set(this.routerData, id, res.data.data.data[id])
                }
            })
        }
    },
    mounted() {
        this.getUserInfo();
        this.getTz();
        this.getUserPermissionInfo();


        // var chart = document.getElementById('chart').getContext('2d'),
        //     gradient = chart.createLinearGradient(0, 0, 0, 450);
        // gradient.addColorStop(0, 'rgba(0, 199, 214, 0.32)');
        // gradient.addColorStop(0.3, 'rgba(0, 199, 214, 0.1)');
        // gradient.addColorStop(1, 'rgba(0, 199, 214, 0)');


        var data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            datasets: [{
                label: 'Applications',
                // backgroundColor: gradient,
                pointBackgroundColor: '#00c7d6',
                borderWidth: 1,
                borderColor: '#0e1a2f',
                data: [60, 45, 80, 30, 35, 55, 25, 80, 40, 50, 80, 50]
            }]
        };

        var options = {
            responsive: true,
            maintainAspectRatio: true,
            animation: {
                easing: 'easeInOutQuad',
                duration: 520
            },
            scales: {
                yAxes: [{
                    ticks: {
                        fontColor: '#5e6a81'
                    },
                    gridLines: {
                        color: 'rgba(200, 200, 200, 0.08)',
                        lineWidth: 1
                    }
                }],
                xAxes: [{
                    ticks: {
                        fontColor: '#5e6a81'
                    }
                }]
            },
            elements: {
                line: {
                    tension: 0.4
                }
            },
            legend: {
                display: false
            },
            point: {
                backgroundColor: '#00c7d6'
            },
            tooltips: {
                titleFontFamily: 'Poppins',
                backgroundColor: 'rgba(0,0,0,0.4)',
                titleFontColor: 'white',
                caretSize: 5,
                cornerRadius: 2,
                xPadding: 10,
                yPadding: 10
            }
        };

        // var chartInstance = new Chart(chart, {
        //     type: 'line',
        //     data: data,
        //     options: options
        // });

        // document.querySelector('.open-right-area').addEventListener('click', function () {
        //     document.querySelector('.app-right').classList.add('show');
        // });

        document.querySelector('.close-right').addEventListener('click', function () {
            document.querySelector('.app-right').classList.remove('show');
        });

        // document.querySelector('.menu-button').addEventListener('click', function () {
        //     document.querySelector('.app-left').classList.add('show');
        // });

        document.querySelector('.close-menu').addEventListener('click', function () {
            document.querySelector('.app-left').classList.remove('show');
        });
    }
};
</script>
      
<style scoped>
/* @import  ("../assets/css/googleCss2.css"); */
/* @import  ("../assets/css/UserFront.css"); */
@import "../assets/css/UserFront.css";
@import "../assets/css/googleCss2.css";
</style>
      