<template>
    <div>
        <h1 style="margin-top: 10px;">用户管理</h1>
        <el-table :data="tableData2" border style="width: 100%">
            <el-table-column fixed prop="nickName" label="昵称" width="150">
            </el-table-column>
            <el-table-column prop="u0001" label="用户名" width="120">
            </el-table-column>
            <el-table-column prop="email" label="邮箱" width="300">
            </el-table-column>
            <el-table-column prop="permissionDescription" label="角色" width="120">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">角色</el-button>
                    <el-button type="text" @click="deleteModel(scope.row)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>


        <div style="bottom: 20px">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage3"
                :page-size="contData.size" :page-count="contData.pages" layout="prev, pager, next, jumper"
                class="pageTool center">
            </el-pagination>
            <!-- <el-button @click="toggleSelection([tableData[1], tableData[2]])">确定</el-button> -->
            <!-- <el-button @click="toggleSelection()">取消选择</el-button> -->
        </div>


        <el-dialog title="提示" :visible.sync="dialogVisible" width="400px" :modal-append-to-body="false">

            <span style="display: inline-block ;color: #000000 ;" class="area-text">名称： {{ this.name.nickName }}</span>
            <br>
            <br>
            <span style="display: inline-block ;color: #000000 ;" class="area-text">角色：</span>
            <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                <SelectRole ref="SelectRole" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitUserRole()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="提示" :visible.sync="dialogVisible2" width="30%" :modal-append-to-body="false">
            <span>请注意，此操作不可恢复，确认要删除吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="deleteModel1()">确 定</el-button>
            </span>
        </el-dialog>

        <!-- <el-dialog title="新增" :visible.sync="dialogVisible1" width="400px" :modal-append-to-body="false">
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">名称：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-input v-model="NewModelData.permissionDescription" placeholder="请输入内容"
                        style="margin-left: auto;margin-right: auto;"></el-input>
                </div>
            </div>
            <div class="lysoft-form-table">
                <span style="display: inline-block ;color: #000000 ;" class="area-text">排序：</span>
                <div style="display: inline-block ;width: 70%!important;margin-left: auto;margin-right: auto;">
                    <el-input v-model="NewModelData.porder" placeholder="请输入内容"
                        style="margin-left: auto;margin-right: auto;"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible1 = false">取 消</el-button>
                <el-button type="primary" @click="submitModel()">确 定</el-button>
            </span>
        </el-dialog> -->


    </div>
</template>

<script>
import axios from "axios";
import SelectRole from "./SelectRole.vue";
export default {
    components: {
        SelectRole
    },
    data() {
        return {
            tableData2: [],
            contData: {},
            fromData: {
                pageSize: 16,
                pageNum: 1,
            },
            currentPage3: 1,
            dialogVisible: false,
            dialogVisible2: false,
            name: {},
            UserData: {},
        };
    },
    methods: {
        deleteModel(row) {
            this.dialogVisible2 = true;
            this.UserData.u0000 = row.u0000
        },
        openNotification(position = null, color, info, info2) {
            const noti = this.$vs.notification({
                progress: 'auto',
                color,
                position,
                title: info,
                text: info2
            })
        },
        deleteModel1() {
            axios
                .post(`/api/UserCenter/UserController/user/DeleteUser`, this.UserData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        this.dialogVisible2 = false;
                        this.openNotification('top-right', 'success', "提示", "操作成功")
                        this.getUserList()
                    }
                })
        },
        getUserList() {
            axios
                .post("/api/UserCenter/UserController/user/GetUserList", this.fromData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.contData = res.data.data
                    this.tableData2 = res.data.data.list;
                })
        },
        handleClick(row) {
            this.dialogVisible = !this.dialogVisible
            this.name = row

        },
        handleCurrentChange() {
            this.fromData.pageNum = this.currentPage3
            getUserList()
        },
        submitUserRole() {
            this.dialogVisible = false
            const targetComponent = this.$refs.SelectRole;
            if (targetComponent&&targetComponent.value) {
                this.name.u0002 = targetComponent.value
                axios
                    .post(`/api/UserCenter/UserController/user/AdminSetUserRole`, this.name, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((res) => {
                        // this.contData = res.data.data;
                        this.openNotification('top-right', 'success', "提示", "操作成功")
                        targetComponent.value = ''
                    })
            }
        }
    },
    mounted() {
        this.getUserList()
    }
};
</script>

<style scoped></style>



// axios
// .post("/api/ar/arGetAll",this.fromData, {
// headers: {
// 'Content-Type': 'multipart/form-data'
// }
// })
// .then((res) => {
// this.contData = res.data.data;
// })