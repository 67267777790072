import Vue from 'vue'
import VueRouter from 'vue-router'
import Publish from '../components/Publish.vue'
import HomePage from '../components/HomePage.vue'
import Navigation from '../components/Navigation.vue'
import Footer from '../components/Footer.vue'
import ShowContent from '../components/ShowContent.vue'
import LeftSide from '../components/LeftSide.vue'
import Login from '../components/Login.vue'
import SearchPage from '../components/SearchPage.vue'
import Comment from '../components/Comment.vue'
import UserFront from '../components/UserFront.vue'
import UserCard from '../components/UserCard.vue'
import UserCenter from '../components/UserCenter.vue'
import Cards from '@/components/Cards.vue'
import floatBar from '@/components/floatBar.vue'
import PersonalDetails from '@/components/PersonalDetails.vue'
import ModelCenter from '@/components/ModelCenter.vue'
import RoleCenter from '@/components/RoleCenter.vue'
import UserController from '@/components/UserController.vue'
import WriterCenter from '@/components/WriterCenter.vue'
import NotifyView from '@/components/NotifyView.vue'
import sendNotify from '@/components/sendNotify.vue'
import arCenter from '@/components/arCenter.vue'
import Gpt from '@/components/Gpt.vue'
import DicController from '@/components/DicController.vue'
import ArDicController from '@/components/ArDicController.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
    
  },
  {
    path: '/notify',
    name: 'NotifyView',
    component: NotifyView
  },
  
  // {
  //   path: '/',
  //   name: 'cards',
  //   component: Cards
  // },
  {
    path: '/publish/:id',
    // path: '/publish',
    name: 'publish',
    component: Publish
  },
  {
    path: '/',
    name: 'Navigation',
    component: Navigation
  },
  {
    path: '/',
    name: 'Footer',
    component: Footer
  },
  {
    path: '/contentShow/:id',
    name: 'ShowContent',
    component: ShowContent
  },
  {
    path: '/',
    name: 'LeftSide',
    component: LeftSide
  },
  {
    path: '/SearchPage',
    name: 'SearchPage',
    component: SearchPage
  },
  {
    path: '/Gpt',
    name: 'Gpt',
    component: Gpt
  },
  {
    path: '/Comment',
    name: 'Comment',
    component: Comment
  },  
  {
    path: '/UserFront',
    name: 'UserFront',
    component: UserFront,
    children:[
      {
        path:'/UserFront/home',
        // name:'home',
        component:WriterCenter,
      },
      {
        path:'/UserFront/arCenter',
        // name:'home',
        component:arCenter,
      }    
    ]
  },    
  {
    path: '/floatBar',
    name: 'floatBar',
    component: floatBar
  },  
  // {
  //   path: '/UserCard',
  //   name: 'UserCard',
  //   component: UserCard
  // },  
  {
    path: '/UserCenter',
    name: 'UserCenter',
    component: UserCenter,
    children:[
      {
        path:'/UserCenter/home',
        // name:'home',
        component:UserCard,
      },      
      {
        path:'/UserCenter/PersonalDetails',
        // name:'home',
        component:PersonalDetails,
      },
      {
        path:'/UserCenter/ModelCenter',
        // name:'home',
        component:ModelCenter,
      },      
      {
        path:'/UserCenter/RoleCenter',
        // name:'home',
        component:RoleCenter,
      },      
      {
        path:'/UserCenter/UserController',
        // name:'home',
        component:UserController,
      },      
      {
        path:'/UserCenter/sendNotify',
        // name:'home',
        component:sendNotify,
      },      
      {
        path:'/UserCenter/DicController',
        // name:'home',
        component:DicController,
      },      
      {
        path:'/UserCenter/ArDicController',
        // name:'home',
        component:ArDicController,
      },
      
    ]
  },

]

const router = new VueRouter({
  routes,
  mode:'history'
})




const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
